import React, { useEffect, useState } from "react";
import "../assets/css/Contact.css";
import data from "../../src/component/ApiUrl/meta_api";
import { Helmet } from "react-helmet";
import AOS from "aos";

function Contact() {
  const [meta, setMeta] = useState(null);

  const fetchData = () => {
    const ContactMeta = data[0]?.contact_page;
    if (ContactMeta) {
      setMeta(ContactMeta);
    }
  };

  useEffect(() => {
    fetchData();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{meta ? meta.title : "Contact"}</title>
      </Helmet>
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">
            Contact
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              {/* <li className="breadcrumb-item">
                <a href="#">Pages</a>
              </li> */}
              <li className="breadcrumb-item active" aria-current="page">
                Contact
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* contact start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 justify-content-center mb-5">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
              data-aos="fade-left"
            >
              <div className="bg-light text-center h-100 p-5 Contact_card_section">
                <div
                  className="btn-square bg-white rounded-circle mx-auto mb-4"
                  style={{ width: "90px", height: "90px" }}
                >
                  <i className="fa fa-phone fa-2x text-warning"></i>
                </div>
                <h4 className="mb-3">Phone Number</h4>
                <p className="mb-2">+91- 812 231 7080</p>
                {/* <p className="mb-4">+012 345 67890</p> */}
                <a className="btn btn-warning px-4" href="tel:+0123456789">
                  Call Now <i className="fa fa-arrow-right ms-2"></i>
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
              data-aos="fade-down"
            >
              <div className="bg-light text-center h-100 p-5 Contact_card_section">
                <div
                  className="btn-square bg-white rounded-circle mx-auto mb-4"
                  style={{ width: "90px", height: "90px" }}
                >
                  <i className="fa fa-envelope-open fa-2x text-warning"></i>
                </div>
                <h4 className="mb-3">Email Address</h4>
                <p className="mb-2">contact@scimitarautomation.com</p>
                {/* <p className="mb-4">support@example.com</p> */}
                <a
                  className="btn btn-warning px-4"
                  href="mailto:info@example.com"
                >
                  Email Now <i className="fa fa-arrow-right ms-2"></i>
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
              data-aos="fade-right"
            >
              <div className="bg-light text-center h-100 p-5 Contact_card_section">
                <div
                  className="btn-square bg-white rounded-circle mx-auto mb-4"
                  style={{ width: "90px", height: "90px" }}
                >
                  <i className="fa fa-map-marker fa-2x text-warning"></i>
                </div>
                <h4 className="mb-3">Office Address</h4>

                <p className="mb-2">
                  29/30, Pavadai St, Kaikolar Thottam, Chidambaram Colony ,
                  Erode.
                </p>
                <a
                  className="btn btn-warning px-4"
                  href="https://maps.app.goo.gl/jUutHvNe2WRLsGDU6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Direction <i className="fa fa-arrow-right ms-2"></i>
                </a>
                {/* <p className="mb-4">+012 345 67890</p> */}
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 wow fadeInUp" data-wow-delay="0.1s">
              <iframe
                className="w-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3091.8683013965956!2d77.71625617378322!3d11.338217748582728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba96f3ee5491bb5%3A0x183a1229ae82a4b0!2s29%2C%20Pavadai%20St%2C%20Kaikolar%20Thottam%2C%20Chidambaram%20Colony%2C%20Erode%2C%20Tamil%20Nadu%20638001%2C%20India!5e1!3m2!1sen!2sbd!4v1727341415580!5m2!1sen!2sbd"
                frameBorder="0"
                style={{ minHeight: "450px", border: 0 }}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              data-aos="fade-down"
            >
              <p className="fw-medium text-uppercase text-warning mb-2">
                Contact Us
              </p>
              <h1 className="display-5 mb-4">
                If You Have Any Queries, Please Feel Free To Contact Us
              </h1>
              <p className="mb-4">
                Have a question? Need a consultation? We’re here to help. Fill
                out the form below, and one of our representatives will get back
                to you shortly.
              </p>
              <div className="row g-4">
                <div className="col-6">
                  <div className="d-flex">
                    <div className="flex-shrink-0 btn-square bg-warning rounded-circle">
                      <i className="fa fa-phone text-white"></i>
                    </div>
                    <div className="ms-3">
                      <h6>Call Us</h6>
                      <span>+012 345 67890</span>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex">
                    <div className="flex-shrink-0 btn-square bg-warning rounded-circle">
                      <i className="fa fa-envelope text-white"></i>
                    </div>
                    <div className="ms-3">
                      <h6>Mail Us</h6>
                      <span>info@example.com</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <form>
                <div className="row g-3" data-aos="fade-up">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                      />
                      <label htmlFor="name">Your Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Your Email"
                      />
                      <label htmlFor="email">Your Email</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        placeholder="Subject"
                      />
                      <label htmlFor="subject">Subject</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Leave a message here"
                        id="message"
                        style={{ height: "150px" }}
                      ></textarea>
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-success py-3 px-5" type="submit">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* contact end */}

      {/* popup model start */}
      {/* 
      <div
        className="container popup_NavbarModel marginTB"
       
      >
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 bg-secondary text-center p-5">
            <div className="">
              <img
                className="rounded-circle"
                src={require("../assets/img/popup image.jpg")}
                style={{ width: "150px" }}
              ></img>
              <p className="text-white marginTB">
                State Variables: Each fact (years of experience, team members,
                etc.) has its own state variable initialized to its target
                value. Counting Logic: The countDown function uses setInterval
                to decrement the count until it reaches 0, updating the state on
                each interval. Cleanup: The cleanup function ensures that
                intervals are cleared when the component unmounts, preventing
                potential memory leaks.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 bg-warning text-center p-5">
            <div className="row">
              <div className="col-lg-10">
                <h3 className="">GET IN TOUCH</h3>
              </div>
              <div className="col-lg-2 text-end">
                <button className="btn text-danger border">X</button>
              </div>
            </div>
            <div className="row marginTB">
              <form onClick={closePopup}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Mobile Number"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Location"
                    required
                  />
                </div>
                <div className="mb-3">
                  <select className="form-select" required>
                    <option value="" disabled selected>
                      Select Service Type
                    </option>
                    <option value="service1">Hardware Services</option>
                    <option value="service2">Machine Retrofit</option>
                    <option value="service3">Panel Fabrication</option>
                    <option value="service3">Onsite & Field Services</option>
                    <option value="service3">Training Services</option>
                  </select>
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    rows="3"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div> */}

      {/* popup model end */}
    </>
  );
}

export default Contact;
