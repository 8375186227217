import "../assets/css/Siemens.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

function Siemens() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []); // Added data as a dependency// Added data as a dependency
  return (
    <>
      {/* <Helmet>
        <title>{meta ? meta.title : "About"}</title>
      </Helmet> */}
      {/* <!-- Page Header Start --> */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">
            Siemens
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Siemens
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container ">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 " data-aos="fade-right">
            <h2 className="text-warning">
              Siemens PLC Solutions at Scimitar Automation
            </h2>
            <p className="pasagejustify">
              At Scimitar Automation, we specialize in providing advanced
              Siemens PLC (Programmable Logic Controller) solutions that drive
              efficiency and innovation in industrial automation. Siemens PLCs
              are renowned for their robustness, flexibility, and scalability,
              making them ideal for a wide range of applications across various
              industries.{" "}
            </p>
          </div>

          <div className="col-lg-6 col-md-6" data-aos="fade-left">
            <img
              className="about-image"
              src={require("../assets/img/products/Siemens/PLC_Solutions.jpg")}
              alt="Who we are"
            />
          </div>
        </div>
      </div>

      <div className=" ">
        <div className="p-5 marginTB  bg-dark">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="text-warning">Our Siemens PLC Services</h2>
              <p className="text-white">
                At Scimitar Automation, we offer a comprehensive suite of
                services centered around Siemens PLCs
              </p>
            </div>
            <div className="col-lg-6">
              <img
                className="Digitalization"
                src={require("../assets/img/products/Siemens/8dcc2f99-a70c-475a-b70e-811b6e3d43d8.gif")}
                alt="Image 3"
              />
            </div>
            <div className="col-lg-6 marginTB" data-aos="zoom-out">
              <h4 className="text-warning">More efficient e-car production</h4>
              <p className="text-white">
                At Scimitar Automation, we offer a comprehensive range of
                Siemens PLC services, starting with consultation and system
                design to tailor solutions to your specific automation needs.
                Our team manages seamless installation and integration with your
                existing infrastructure, ensuring minimal downtime. Our skilled
                engineers program and configure the PLCs for maximum efficiency,
                followed by thorough testing and commissioning to ensure optimal
                performance. We provide extensive training for your team and
                ongoing support to maximize system benefits, alongside tailored
                maintenance plans and upgrade services to keep your PLC system
                up to date and reliable..
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>

      <div className=" marginTB">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card exploreCard_1 p-1" data-aos="zoom-in">
              <img
                className="experienced-img"
                data-aos="zoom-in"
                src={require("../assets/img/products/Siemens/Consultation and System.jpg")}
              ></img>

              <h5 className="mt-3 text-warning text-center">
                Consultation and System Design
              </h5>

              <p className="siemensCard pasagejustify p-2">
                <hr></hr>
                Our experienced team works closely with you to assess your
                automation needs and design a customized HMI solution that
                aligns with your operational goals. We ensure that the interface
                is user-friendly and tailored to your specific processes.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card exploreCard_1 p-1" data-aos="zoom-out">
              <img
                className="experienced-img"
                src={require("../assets/img/products/Siemens/Configuration.jpg")}
              ></img>

              <h5 className="mt-3 text-warning text-center ">
                Configuration and Programming
              </h5>
              <p className="siemensCard pasagejustify p-2">
                <hr></hr>
                Our skilled engineers specialize in configuring Siemens HMIs to
                meet your unique requirements. Using the latest software tools,
                we develop intuitive interfaces that facilitate efficient
                monitoring and control of your operations.{" "}
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card exploreCard_1 p-1" data-aos="zoom-in">
              <img
                className="experienced-img"
                src={require("../assets/img/products/Siemens/Maintenance.jpg")}
              ></img>

              <h5 className=" mt-3 text-warning text-center ">
                Maintenance and Upgrades
              </h5>
              <p className="siemensCard pasagejustify p-2">
                <hr></hr>
                To ensure long-term reliability and performance, we provide
                tailored maintenance plans and upgrade services for your HMI
                systems, keeping them up to date with the latest technology and
                features.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 ">
            <div className="card  exploreCard_1 p-1" data-aos="zoom-out">
              <img
                className="experienced-img"
                src={require("../assets/img/products/Siemens/Testing and Validation.jpg")}
              ></img>

              <h5 className="mt-3 text-warning text-center ">
                Testing and Validation{" "}
              </h5>
              <p className="siemensCard pasagejustify p-2">
                <hr></hr>
                Prior to deployment, we conduct comprehensive testing and
                validation of the HMI system to ensure all components function
                correctly and meet your performance standards.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-warning  p-3">
        <div className=" marginTB X-selector-head  ">
          <div className="row">
            <div className="col-lg-4 Frequency-Drives" data-aos="fade-right">
              <img
                className=" X-selector"
                src={require("../assets/img/products/Siemens/Frequency.jpg")}
                alt="Image 3"
              />
            </div>
            <div className="col-lg-8 Frequency-Drives  " data-aos="fade-left">
              <h2 className="text-warning text-start  ">
                {" "}
                Frequency Drives (VFD) Solutions{" "}
              </h2>
              <p className="text-dark pasagejustify p-2">
                At Scimitar Automation, we provide a comprehensive range of
                services centered around Siemens VFD technology, including
                consultation and system design to create tailored VFD solutions
                for optimal performance; professional installation and
                commissioning to ensure your systems are set up correctly;
                ongoing maintenance and support to keep your VFD systems in peak
                condition and minimize downtime; and training programs designed
                to empower your staff with the knowledge of VFD functionality
                and benefits, enabling them to maximize the efficiency of your
                automation systems.
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>

      <div className=" marginTB container">
        <div className="row">
          <div className="col-lg-6  col-md-8 marginTB" data-aos="zoom-in">
            <h2 className="text-warning"> At Scimitar Automation Siemens</h2>
            <h4>Siemens SCADA</h4>
            <p className="pasagejustify ">
              {" "}
              we provide an extensive array of services focused on Siemens SCADA
              systems. Our expert team collaborates closely with you to design
              tailored SCADA solutions that fit your operational needs
              perfectly. We handle the installation and configuration to
              guarantee your system is up and running smoothly from day one.
              Plus, our dedicated support team is always on hand for maintenance
              and troubleshooting, ensuring everything operates without a hitch.
              We also offer in-depth training programs to empower your staff,
              equipping them with the skills to leverage the SCADA system
              effectively and unlock its full potential..
            </p>
          </div>
          <div className="col-lg-6  col-md-4">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-left">
                <img
                  className="Automation-Ahead1"
                  src={require("../assets/img/products/Siemens/Automation-Ahead1.jpg")}
                  alt="Image 1"
                />
              </div>
              <div className="col-lg-6" data-aos="fade-left">
                <img
                  className="Automation-Ahead2"
                  src={require("../assets/img/products/Siemens/Automation-Ahead4.jpg")}
                  alt="Image 2"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6" data-aos="fade-left">
                <img
                  className="Automation-Ahead3"
                  src={require("../assets/img/products/Siemens/Automation-Ahead3.jpg")}
                  alt="Image 3"
                />
              </div>
              <div className="col-lg-6">
                <img
                  className="Automation-Ahead4"
                  src={require("../assets/img/products/Siemens/1f8fafc9-b9e9-4cfc-b6e7-8c98d628871e.gif")}
                  alt="Image 4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Siemens;
