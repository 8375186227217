import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../assets/css/About.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import data from "../../src/component/ApiUrl/meta_api";
import { Helmet } from "react-helmet";

function AboutUs() {
  const [meta, setMeta] = useState(null);

  const fetchData = () => {
    const AboutMeta = data[0]?.about_page;
    if (AboutMeta) {
      setMeta(AboutMeta);
    }
  };

  useEffect(() => {
    fetchData();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{meta ? meta.title : "About"}</title>
      </Helmet>
      {/* <!-- Page Header Start --> */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">
            About Us
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              {/* <li className="breadcrumb-item">
                <Link to="/pages">Pages</Link>
              </li> */}
              <li className="breadcrumb-item active" aria-current="page">
                About Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header Start --> */}

      {/* Who we are start */}
      <div className="container ">
        <div className="row ">
          <div className="col-lg-5 col-md-5 col-sm-12" data-aos="fade-down">
            <img
              className="who_we_are_aboutImg object-fit-cover"
              src={require("../assets/img/About_Us/images.jpg")}
            ></img>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12" data-aos="fade-left">
            <div className="card who_we_are_about p-4 ">
              <h4 className="">About us</h4>
              <h2 className="text-warning">At Scimitar Hindustan </h2>
              <p className="pasagejustify">
                we’ve built a legacy of excellence in the field of industrial
                automation. Founded in 2017, our goal has been to offer
                businesses the tools they need to stay ahead of the curve. From
                automating factory floors to implementing control systems, we
                empower companies to achieve their operational goals.
              </p>

              <p className="pasagejustify">
                Welcome to Scimitar Hindustan, where industrial innovation meets
                operational excellence. we specialize in designing and
                implementing industrial automation solutions tailored to meet
                the needs of industries throughout worldwide. Our Mission is to
                Create interconnected and adaptive manufacturing environment
                with maximize Efficiency, Sustainability
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* who we are end */}

      {/* vision mission start */}

      <div className="Vision-bg  mt-5">
        <div className="container p-3">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-sm-12 mt-3">
              <h2 className="text-warning">Vision</h2>
              <p className="text-white pasagejustify">
                we are at the forefront of the industrial revolution in
                Industrial, Home and Marine Automation. Our vision is to empower
                industries related with automation solutions that redefine
                Efficiency, Reliability, and Sustainability.We envision a world
                where factories and industrial plants run autonomously,to
                optimize performance, predict maintenance needs, and minimize
                energy consumption.With a client-first approach, we deliver
                bespoke solutions tailored to meet the specific needs of each
                project, ensuring that every system as your partner in unlocking
                the full potential of industrial automation, helping businesses
                evolve, innovate, and succeed
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="card mt-3">
                <img
                  className=""
                  src={require("../../src/assets/img/About_Us/VisionMission.jpg")}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Mission-bg mt-3">
        <div className="container p-3">
          <div className="row">
            <div className="col-lg-4 col-md-3 col-sm-12">
              <div className="card mt-3 ">
                <img
                  className=""
                  src={require("../../src/assets/img/About_Us/VisionMission.jpg")}
                ></img>
              </div>
            </div>
            <div className="col-lg-8 col-md-6 col-sm-12 mt-3">
              <h2 className="text-warning">Mission</h2>
              <p className="text-white">
                Our mission at Scimitar Automation is to lead the way in
                industrial automation along with Home and Marine Automation by
                providing world-class, forward-thinking solutions that enable
                our clients to embrace the future of manufacturing.
                Sustainability is a key driver of our mission.We work closely
                with our clients to understand their unique challenges and
                goals, delivering customized automation systems that align with
                their specific requirements. By providing expert consultation,
                maintenance, and support.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* vision mission end */}

      {/* our core value start */}

      <div className="container mt-5">
        <h1 className="text-center text-warning">Our Core Values</h1>
      </div>

      <div className="container mt-5">
        <div className="row g-3 justify-content-center mb-5">
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="bg-light text-center h-100 p-2 justify-cont"
              data-aos="fade-down"
            >
              <div
                className="btn-square bg-white rounded-circle mx-auto mb-4"
                style={{ width: "90px", height: "90px" }}
              >
                <i className="fa-solid fa-lightbulb fa-2x text-warning p-3 rounded-circle"></i>
              </div>
              <h4 className="mb-3 mt-2">Innovation</h4>
              <p className="mb-2 justified-text">
                We are dedicated to staying at the technological advancements in
                industrial automation. Explore new solutions and methodologies
                that deliver superior results.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="bg-light text-center h-100 p-2 justify-cont"
              data-aos="fade-up"
            >
              <div
                className="btn-square bg-white rounded-circle mx-auto mb-4"
                style={{ width: "90px", height: "90px" }}
              >
                <i class="fa-solid fa-star fa-2x text-warning"></i>
              </div>
              <h4 className="mb-3 mt-2">Quality</h4>
              <p className="mb-2 justified-text">
                Quality is the cornerstone of everything we do. From the design
                implementation and support, highest standards to ensure that our
                solutions meet and exceed client expectations.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="bg-light text-center h-100 p-2 justify-cont"
              data-aos="fade-down"
            >
              <div
                className="btn-square bg-white rounded-circle mx-auto mb-4"
                style={{ width: "90px", height: "90px" }}
              >
                <i class="fa-solid fa-handshake  fa-2x text-warning"></i>
              </div>
              <h4 className="mb-3 mt-2">Client-Focused</h4>
              <p className="mb-2 justified-text ">
                We prioritize understanding our clients' unique needs and
                challenges. Our solutions are designed with a focus on
                delivering tangible benefits and fostering long-term
                partnerships.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="bg-light text-center h-100 p-2 justify-cont"
              data-aos="fade-up"
            >
              <div
                className="btn-square bg-white rounded-circle mx-auto mb-4"
                style={{ width: "90px", height: "90px" }}
              >
                <i class="fa-solid fa-scale-balanced fa-2x text-warning"></i>
              </div>
              <h4 className="mb-3 mt-2">Integrity</h4>
              <p className="mb-2 justified-text">
                We operate with transparency and honesty in all our dealings.
                Building trust with our clients through ethical practices and
                reliable solutions is fundamental to our success.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* our core value start */}
      {/* 
      <div className="container  ">
        <h1 className="text-center text-warning">Our Core Values</h1>
      </div>
      <div className="container mt-5">
        <div className="row g-3 justify-content-center mb-5">
          <div
            className="col-lg-3 col-md-6 wow fadeInUp "
            data-wow-delay="0.1s"
            style={{ width: "250px", height: "400px" }}
          >
            <div className="bg-light text-center h-100 p-2 justify-cont w-100 h-100 ">
              <div
                className="btn-square bg-white rounded-circle mx-auto mb-4"
                style={{ width: "90px", height: "90px" }}
              >
                <i className="fa-solid fa-lightbulb fa-2x text-warning p-3 rounded-circle"></i>
              </div>
              <h4 className="mb-3 mt-2">Innovation</h4>
              <p className="mb-2 justified-text">
                We are dedicated to staying at the forefront of technological
                advancements in industrial automation. Our commitment to
                innovation to continuously explore new solutions and
                methodologies that deliver superior results.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="bg-light text-center  p-2 justify-cont "
              style={{ width: "250px", height: "400px" }}
            >
              <div
                className="btn-square bg-white rounded-circle mx-auto mb-4"
                style={{ width: "90px", height: "90px" }}
              >
                <i class="fa-solid fa-star fa-2x text-warning"></i>
              </div>
              <h4 className="mb-3 mt-2">Quality</h4>
              <p className="mb-2 justified-text">
                Quality is the cornerstone of everything we do. From the design
                implementation and support, highest standards to ensure that our
                solutions meet and exceed client expectations.
              </p>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ width: "250px", height: "400px" }}
          >
            <div className="bg-light text-center h-100 p-2 justify-cont">
              <div
                className="btn-square bg-white rounded-circle mx-auto mb-4"
                style={{ width: "90px", height: "90px" }}
              >
                <i class="fa-solid fa-handshake  fa-2x text-warning"></i>
              </div>
              <h4 className="mb-3 mt-2">Client-Focused</h4>
              <p className="mb-2 justified-text ">
                We prioritize understanding our clients' unique needs and
                challenges. Our solutions are designed with a focus on
                delivering tangible benefits and fostering long-term
                partnerships.
              </p>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ width: "250px", height: "400px" }}
          >
            <div className="bg-light text-center h-100 p-2 justify-cont">
              <div
                className="btn-square bg-white rounded-circle mx-auto mb-4"
                style={{ width: "90px", height: "90px" }}
              >
                <i class="fa-solid fa-scale-balanced fa-2x text-warning"></i>
              </div>
              <h4 className="mb-3 mt-2">Integrity</h4>
              <p className="mb-2 justified-text">
                We operate with transparency and honesty in all our dealings.
                Building trust with our clients through ethical practices and
                reliable solutions is fundamental to our success.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* our core value end */}

      {/* our core value end */}

      {/* team start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h1 className="fw-medium text-uppercase text-warning mb-2">
              Our Team
            </h1>
            <h1 className="display-5 mb-5">Dedicated Team Members</h1>
          </div>
          <div className="row g-4" data-aos="zoom-in">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <img
                  className="img-fluid"
                  src={require("../assets/img/About_Us/Team_1.jpg")}
                  alt="Rob Miller"
                />
                <div className="d-flex">
                  <div
                    className="flex-shrink-0 btn-square bg-danger"
                    style={{ width: "90px", height: "90px" }}
                  >
                    <i className="fa fa-2x fa-share text-white"></i>
                  </div>
                  <div
                    className="position-relative overflow-hidden bg-light d-flex flex-column justify-content-center w-100 ps-4"
                    style={{ height: "90px" }}
                  >
                    <h5>Rob Miller</h5>
                    <span className="text-warning">CEO & Founder</span>
                    <div className="team-social">
                      <a
                        className="btn btn-square btn-dark rounded-circle mx-1"
                        href="#"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a
                        className="btn btn-square btn-dark rounded-circle mx-1"
                        href="#"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a
                        className="btn btn-square btn-dark rounded-circle mx-1"
                        href="#"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="team-item">
                <img
                  className="img-fluid"
                  src={require("../assets/img/About_Us/Team_2.jpg")}
                  alt="Adam Crew"
                />
                <div className="d-flex">
                  <div
                    className="flex-shrink-0 btn-square bg-danger"
                    style={{ width: "90px", height: "90px" }}
                  >
                    <i className="fa fa-2x fa-share text-white"></i>
                  </div>
                  <div
                    className="position-relative overflow-hidden bg-light d-flex flex-column justify-content-center w-100 ps-4"
                    style={{ height: "90px" }}
                  >
                    <h5>Adam Crew</h5>
                    <span className="text-warning">Project Manager</span>
                    <div className="team-social">
                      <a
                        className="btn btn-square btn-dark rounded-circle mx-1"
                        href="#"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a
                        className="btn btn-square btn-dark rounded-circle mx-1"
                        href="#"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a
                        className="btn btn-square btn-dark rounded-circle mx-1"
                        href="#"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="team-item">
                <img
                  className="img-fluid"
                  src={require("../assets/img/About_Us/Team_3.jpg")}
                  alt="Peter Farel"
                />
                <div className="d-flex">
                  <div
                    className="flex-shrink-0 btn-square bg-danger"
                    style={{ width: "90px", height: "90px" }}
                  >
                    <i className="fa fa-2x fa-share text-white"></i>
                  </div>
                  <div
                    className="position-relative overflow-hidden bg-light d-flex flex-column justify-content-center w-100 ps-4"
                    style={{ height: "90px" }}
                  >
                    <h5>Peter Farel</h5>
                    <span className="text-warning">Engineer</span>
                    <div className="team-social">
                      <a
                        className="btn btn-square btn-dark rounded-circle mx-1"
                        href="#"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a
                        className="btn btn-square btn-dark rounded-circle mx-1"
                        href="#"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a
                        className="btn btn-square btn-dark rounded-circle mx-1"
                        href="#"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* team end */}
    </>
  );
}

export default AboutUs;
