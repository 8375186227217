import { Helmet } from "react-helmet";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "../assets/css/Cement.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import React, { useEffect } from "react";

function Cement() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <>
      {/* <Helmet>
        <title>{meta ? meta.title : "About"}</title>
      </Helmet> */}
      {/* <!-- Page Header Start --> */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">Cement</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/">Industries</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Cement
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header Start --> */}

      <div className="container-Optimize mt-5">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-6">
            <h2 className="text-warning">Optimize Your Cement Production</h2>
            <p className="text-dark pasagejustify" data-aos="fade-right">
              At Scimitar Hindustan, we know that the cement industry faces
              unique challenges, and we’re here to help you tackle them head-on.
              Every cement operation is unique, and we believe your automation
              solutions should be too. Our team will work closely with you to
              understand your specific needs and challenges, creating customized
              strategies that fit your goals and improve your processes.
            </p>
            <button className="btn btn-warning px-4 py-2 rounded-pill mt-3">
              Explore
            </button>
          </div>
          <div className="col-lg-6 col-md-4">
            <img
              className="img-fluid cement-image"
              data-aos="fade-up"
              src={require("../assets/img/Industries/Cement/cement-raw-mill.png")}
              alt="Cement Industry"
            />
          </div>
          <div className="col-lg-2 col-md-2">
            <img
              className="img-fluid cement-image theree-png"
              data-aos="fade-up"
              src={require("../assets/img/Industries/Cement/pngwing.com (2).png")}
              alt="Cement Industry"
            />
          </div>
        </div>
      </div>

      <div className="Why-Choose-head">
        <div className="row">
          <div className="col-lg-5 ">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-up">
                <div className="whychooseDiv"></div>
              </div>

              <div className="col-lg-6" data-aos="fade-down">
                <img
                  className="cementsWhychooseimg"
                  src={require("../assets/img/Industries/Cement/whychooses.jpg")}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <h2 className="text-warning" data-aos="fade-down">
              Why Choose Automation for Cement Manufacturing?
            </h2>
            <p className="pasagejustify" data-aos="fade-down">
              Boost Efficiency: Our integrated systems make production smoother
              and faster, reducing the need for manual work and helping you get
              more done in less time..
            </p>

            <div className="row" data-aos="fade-up">
              <div className="col-lg-6 col-md-12">
                <div className="row">
                  <div className="col-12">
                    <i class="fa-solid fa-truck-fast"></i>
                  </div>
                  <div className="col-12">
                    <h4 className="text-warning"> Ensure Quality</h4>
                    <p className="pasagejustify">
                      With our advanced monitoring technologies, you can
                      maintain consistent quality across your products,
                      minimizing defects and meeting industry standards with
                      confidence.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="row">
                  <div className="col-12">
                    <i class="fa-brands fa-shopify"></i>
                  </div>
                  <div className="col-12">
                    <h4 className="text-warning"> Save Energy</h4>
                    <p className="pasagejustify">
                      We focus on energy efficiency, helping you monitor and
                      reduce energy consumption, which can lead to significant
                      cost savings and a smaller carbon footprint.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="row">
                  <div className="col-12">
                    <i class="fa-solid fa-clock"></i>
                  </div>
                  <div className="col-12">
                    <h4 className="text-warning"> Gain Valuable Insights</h4>
                    <p className="pasagejustify">
                      Our data analytics solutions give you real-time insights
                      into your operations, helping you make informed decisions
                      and stay ahead of potential issues before they arise..
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="row">
                  <div className="col-12">
                    <i class="fa-solid fa-right-left"></i>
                  </div>
                  <div className="col-12">
                    <h4 className="text-warning"> Prioritize Safety </h4>
                    <p className="pasagejustify">
                      Your workforce’s safety is our priority. Our solutions
                      come with built-in features that ensure compliance with
                      regulations and foster a secure working environment
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <></>

      <div className="content-section cardBlog mt-2">
        <div className="row">
          <div
            className="col-lg-6 p-5 text-section  bg-transparent "
            data-aos="fade-right"
          >
            <h4 className="text-highlight">Maximize Efficiency</h4>
            <p className="text-description">
              At Scimitar Hindustan, we leverage the power of Model Predictive
              Control (MPC) and advanced analytics to help you reach peak
              performance in your cement manufacturing processes. By utilizing
              these cutting-edge technologies, you can enhance operational
              efficiency, improve product quality, and make data-driven
              decisions that drive success.
            </p>
          </div>
          <div
            className="col-lg-6 p-5 text-section   bg-transparent "
            data-aos="fade-left"
          >
            <h4 className="text-highlight">Future-Proof</h4>
            <p className="text-description">
              Scimitar Hindustan’s innovative solutions enable your cement plant
              to adapt to evolving industry trends and consumer expectations.
              Let us help you build a resilient and future-ready operation that
              meets current demands while preparing for sustained success in the
              future.
            </p>
          </div>
        </div>
      </div>

      <div className="container  Digital-Innovation ">
        <div className="row">
          <div className="col-lg-8 col-md-6" style={{ marginLeft: "-50px" }}>
            <div className="row">
              <div className="col-lg-6 We-Help-head" data-aos="fade-right">
                <img
                  className="We-Help1"
                  src={require("../assets/img/Industries/Cement/We Help.jpg")}
                  alt="We Help 1"
                />
              </div>
              <div className="col-lg-6">
                <div className="col-lg-12" data-aos="fade-down">
                  <img
                    className="We-Help2"
                    src={require("../assets/img/Industries/Cement/We Help2.jpg")}
                    alt="We Help 2"
                  />
                </div>
                <div className="col-lg-12" data-aos="fade-up">
                  <img
                    className="We-Help3"
                    src={require("../assets/img/Industries/Cement/We Help3.jpg")}
                    alt="We Help 3"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 "
            style={{ marginLeft: "50px" }}
            data-aos="zoom-in"
          >
            <h2 className="text-warning">
              Create Smarter Efficiency with Digital Innovation
            </h2>
            <p className="pasagejustify">
              Embrace the future of cement manufacturing with digital
              innovations that streamline processes and enhance productivity. At
              Scimitar Hindustan, we integrate advanced technologies such as IoT
              and artificial intelligence into your cement plant operations. By
              leveraging digital tools, you can not only optimize current
              operations but also lay the groundwork for future advancements,
              ensuring your plant remains competitive in an evolving market.
            </p>
            <div className="row">
              <div className="col-lg-12 pt-1">
                <i class="fa-solid fa-arrow-right text-warning me-2 "></i>This
                digital transformation{" "}
              </div>
              <div className="col-lg-12 pt-1">
                <i class="fa-solid fa-arrow-right text-warning me-2"></i>For
                real-time data collection
              </div>
              <div className="col-lg-12 pt-1">
                <i class="fa-solid fa-arrow-right text-warning me-2"></i>Smarter
                decision-making{" "}
              </div>
              <div className="col-lg-12 pt-1">
                <i class="fa-solid fa-arrow-right text-warning me-2"></i>
                Improved process efficiency
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cement;
