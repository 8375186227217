import "../assets/css/AutomotiveAndTyre.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import React, { useEffect } from "react";

function AutomotiveAndTyre() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <>
      {/* banner start */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">
            Automotive & Tyre
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              {/* <li className="breadcrumb-item">
                <a href="#">Pages</a>
              </li> */}
              <li className="breadcrumb-item active" aria-current="page">
                Automotive & Tire
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* banner end */}

      {/* Automotive and Tire start */}

      <div className="container marginTB">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
            <div className="">
              <img
                className="Automotive_Intro_Image"
                src={require("../assets/img/Industries/Automotive&Tyre/AutomotiveIntro1.jpg")}
                style={{ objectFit: "cover" }}
              ></img>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-12 mt-5"
            data-aos="fade-left"
          >
            <h1 className="text-warning">Introduction</h1>
            <p className="" style={{ textAlign: "justify" }}>
              Welcome to Scimitar Hindustan, where we specialize in providing
              cutting-edge automotive automation and control systems tailored
              specifically for the automotive and tire industries. Our mission
              is to empower manufacturers with innovative solutions that enhance
              efficiency, ensure precision, and drive productivity in an
              increasingly competitive market.
            </p>
          </div>
        </div>
      </div>

      {/* Automotive and Tire end */}

      {/* Comprehensive Automation Solutions
 Start */}

      <div className="container-fluid Comprehensive_Automation p-5 ">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12"></div>
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up">
            <h1 className="text-warning">Comprehensive Automation Solutions</h1>
            <p className="text-white Comprehensive_Automation_Text pasagejustify">
              At Scimitar Hindustan, we understand the complexities and unique
              challenges of automotive manufacturing, offering a comprehensive
              range of solutions that include advanced control systems for
              real-time monitoring and management of processes, ensuring optimal
              performance and reliability; data-driven insights through data
              analytics and IoT technology to enable informed decision-making
              and proactive maintenance; and state-of-the-art robotics and
              automation technologies that enhance flexibility and precision on
              the production line, reducing manual labor and minimizing the risk
              of errors.
            </p>
          </div>
        </div>
      </div>

      {/* Comprehensive Automation Solutions
 End */}

      {/* Challenges in Automotive Manufacturing start */}

      <div className="container-fluid  Challenges_in_Automotive_BG">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <h1 className="text-warning marginTB Challenges_Automotive_ManuText_">
              Challenges in Automotive Manufacturing
            </h1>

            <p
              className="Challenges_Automotive_ManuText pasagejustify"
              data-aos="fade-right"
            >
              Automotive manufacturing faces a range of complex challenges that
              can impact efficiency, quality, and overall competitiveness. At
              Scimitar Hindustan, we recognize these obstacles and provide
              tailored solutions to address them. Key challenges include the
              increasing demand for customization, which necessitates flexible
              manufacturing systems capable of accommodating various models
              without significant downtime; the need for stringent quality
              control, requiring advanced inspection technologies to detect
              defects early in the process;
            </p>

            <p
              className="Challenges_Automotive_ManuText pasagejustify marginTB"
              data-aos="fade-right"
            >
              supply chain disruptions that can lead to delays and increased
              costs, highlighting the importance of robust logistics and
              inventory management systems; and the integration of new
              technologies, which often presents hurdles in terms of training
              and adaptation for the workforce. Additionally, maintaining
              regulatory compliance and addressing sustainability concerns are
              ongoing challenges that manufacturers must navigate. By
              understanding and tackling these challenges, we help automotive
              manufacturers enhance their operations and remain competitive in a
              rapidly evolving market.
            </p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <img
              className="Challenge_automation_Image"
              src={require("../assets/img/Industries/Automotive&Tyre/Challenging.jpg")}
            ></img>
          </div>
        </div>
      </div>

      {/* Challenges in Automotive Manufacturing end */}

      {/* Key Benefits start  */}

      <div className="container ">
        <div className="row">
          <div className="col-lg-1 col-md-5 col-sm-12"></div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <img
              className="Key_Benefits_image "
              src={require("../assets/img/Industries/Automotive&Tyre/what_we_offer.jpg")}
            ></img>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 Key_Benefits_Text">
            <p className="marginTB">WHAT WE OFFER</p>

            <div className="row marginTB">
              <div className="col-lg-2 col-md-2 col-sm-12" data-aos="fade-up">
                <img
                  className=""
                  src={require("../assets/img/Industries/Automotive&Tyre/efficacy.png")}
                  style={{ width: "60px" }}
                ></img>
              </div>
              <div
                className="col-lg-10 col-md-10 col-sm-12"
                data-aos="fade-left"
              >
                <h5 className="text-warning">Increased Efficiency</h5>
                <p className="pasagejustify">
                  Streamlined automation processes allow for faster production
                  cycles and reduced operational costs, maximizing your return
                  on investment.
                </p>
              </div>
            </div>
            <div className="row marginTB">
              <div className="col-lg-2 col-md-2 col-sm-12" data-aos="fade-up">
                <img
                  className=""
                  src={require("../assets/img/Industries/Automotive&Tyre/3712234.png")}
                  style={{ width: "60px" }}
                ></img>
              </div>
              <div
                className="col-lg-10 col-md-10 col-sm-12"
                data-aos="fade-left"
              >
                <h5 className="text-warning">Enhanced Quality Control</h5>
                <p className="pasagejustify">
                  Our systems are designed to maintain high standards of
                  quality, helping to minimize defects and waste in your
                  manufacturing processes
                </p>
              </div>
            </div>
            <div className="row marginTB">
              <div className="col-lg-2 col-md-2 col-sm-12" data-aos="fade-up">
                <img
                  className=""
                  src={require("../assets/img/Industries/Automotive&Tyre/decrease.png")}
                  style={{ width: "60px" }}
                ></img>
              </div>
              <div
                className="col-lg-10 col-md-10 col-sm-12"
                data-aos="fade-left"
              >
                <h5 className="text-warning">Reduced Downtime</h5>
                <p className="pasagejustify">
                  With our proactive maintenance solutions, you can expect fewer
                  interruptions, ensuring that your operations run smoothly and
                  efficiently.
                </p>
              </div>
            </div>
            <div className="row marginTB">
              <div className="col-lg-2 col-md-2 col-sm-12" data-aos="fade-up">
                <img
                  className=""
                  src={require("../assets/img/Industries/Automotive&Tyre/protection.png")}
                  style={{ width: "60px" }}
                ></img>
              </div>
              <div
                className="col-lg-10 col-md-10 col-sm-12"
                data-aos="fade-left"
              >
                <h5 className="text-warning">Safety and Compliance</h5>
                <p className="pasagejustify">
                  We prioritize safety in our solutions, integrating advanced
                  features that promote a secure working environment for your
                  team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Key Benefits end  */}

      {/* Proven Expertise start */}

      <div
        className="container-fluid text-dark p-5  text-center"
        style={{ backgroundColor: "#EFF2F1" }}
      >
        <div className="row">
          <div className="col-lg-12 col-md-6 col-sm-3">
            <h1 className="text-warning">Proven Expertise</h1>

            <p className="" style={{ textAlign: "center" }}>
              With extensive experience in the automotive and tire sectors,
              Scimitar Hindustan has a proven track record of delivering
              successful automation solutions that meet the unique needs of our
              clients. Transform your automotive manufacturing processes with
              Scimitar Hindustan’s innovative automation and control systems.
              Contact us today to explore how our tailored solutions can drive
              efficiency and success in your operations!
            </p>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-312 text-center">
            <button className="btn btn-secondary text-warning ">
              Request a Consultaion <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
          {/* <div className="col-lg-3 col-md-3 col-sm-3">
            <button className="btn btn-danger text-warning proven_Expertise_button2">
              Explore Solution <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div> */}
        </div>
      </div>

      {/* Proven Expertise end */}
    </>
  );
}

export default AutomotiveAndTyre;
