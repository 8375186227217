const data = [
  {
    home_page: {
      title:
        "Discover Scimitar Automation - Learn Through Articles, News, and Upcoming Events",
      meta: {
        meta1: {
          name: "description",
          content:
            "Rolling Energy drives Nigeria's clean energy shift with Compressed Natural Gas (CNG), paving the way for a sustainable, cost-effective future. Install CNG Conversion kits in two wheelers, four wheelers, three wheelers, Heavy Vehicles, and Generators.",
        },
        // meta2: {
        //   name: "robots",
        //   content: "index,follow",
        // },
        // meta3: {
        //   property: "og:url",
        //   content: "https://www.rollingenergyltd.com",
        // },
        // meta4: {
        //   property: "og:title",
        //   content: "Upgrade your vehicle's fuel to CNG with Rolling Energy",
        // },
        // meta5: {
        //   property: "og:image",
        //   content: "https://www.rollingenergyltd.com/Rolling-Energy.png",
        // },
        // meta6: {
        //   property: "og:description",
        //   content:
        //     "Switch to a more sustainable and cost-effective driving experience by upgrading your vehicle's fuel to Compressed Natural Gas (CNG) with Rolling Energy. Embrace eco-friendly mobility without compromising on performance or efficiency. enquiries@rollingenergyltd.com",
        // },
        // meta7: {
        //   name: "twitter:card",
        //   content: "summary",
        // },
        // meta8: {
        //   name: "twitter:title",
        //   content: "Rolling Energy - Your Trusted CNG Solution Provider",
        // },
        // meta9: {
        //   name: "twitter:site",
        //   content: "@rollingenergyltd",
        // },
        // meta10: {
        //   name: "twitter:description",
        //   content:
        //     "Rolling Energy drives Nigeria's clean energy shift with Compressed Natural Gas (CNG), paving the way for a sustainable, cost-effective future. Install CNG Conversion kits in two wheelers, four wheelers, three wheelers, Heavy Vehicles, and Generators.",
        // },
        // meta11: {
        //   name: "twitter:image",
        //   content: "https://www.rollingenergyltd.com/Rolling-Energy.png",
        // },
        // meta12: {
        //   name: "twitter:image:alt",
        //   content: "Rolling Energy Private Limited",
        // },
      },
    },
    about_page: {
      title:
        "Scimitar Automation: Our Story and Commitment - Stay Updated with News and Events",
      meta: {
        meta1: {
          name: "description",
          content:
            "Learn about Rolling Energy Private Limited, an independent oil and gas company founded in 2020 with a mission to transform +'s energy landscape through Compressed Natural Gas (CNG) and convert vehicles to use CNG",
        },
        // meta2: {
        //   name: "robots",
        //   content: "index,follow",
        // },
        // meta3: {
        //   name: "og:type",
        //   content: "business.business",
        // },
        // meta4: {
        //   name: "og:title",
        //   content:
        //     "Rolling Energy Commitment to Clean EnergyUpgrade your vehicle's fuel to CNG with Rolling Energy",
        // },
        // meta5: {
        //   name: "og:image",
        //   content:
        //     "https://www.rollingenergyltd.com/static/media/Banner%20New.dc818b6054bde51beb49.png",
        // },
        // meta6: {
        //   name: "og:description",
        //   content:
        //     "Learn about Rolling Energy Private Limited, an independent oil and gas company founded in 2020 with a mission to transform Nigeria's energy landscape through Compressed Natural Gas (CNG) and convert vehicles to use CNG.",
        // },
        // meta7: {
        //   name: "og:url",
        //   content: "https://www.rollingenergyltd.com/about-us",
        // },
        // meta8: {
        //   property: "business:contact_data:country_name",
        //   content: "Nigeria",
        // },
      },
    },
    digital_marketing_page: {
      title:
        "Scimitar Hindustan – CNG Refuelling Station and  Install CNG Conversion kits in vehicles",
      meta: {
        meta1: {
          name: "description",
          content:
            "Install Rolling Energy CNG Conversion kits in domestic, commercial vehicles, industrial vehicles, and automotive applications in Nigeria",
        },
        // meta2: {
        //   name: "robots",
        //   content: "index,follow",
        // },
        // meta3: {
        //   property: "og:type",
        //   content: "business.business",
        // },
        // meta4: {
        //   property: "og:title",
        //   content:
        //     "Rolling Energy – CNG Refuelling Station and  Install CNG Conversion kits in vehicles",
        // },
        // meta5: {
        //   property: "og:image",
        //   content:
        //     "https://www.rollingenergyltd.com/static/media/person-service.32a1c71919e61ad7f877.jpg",
        // },
        // meta6: {
        //   property: "og:description",
        //   content:
        //     "Install Rolling Energy CNG Conversion kits in domestic, commercial vehicles, industrial vehicles, and automotive applications in Nigeria",
        // },
        // meta7: {
        //   property: "og:url",
        //   content: "https://www.rollingenergyltd.com/services",
        // },
        // meta8: {
        //   property: "business:contact_data:country_name",
        //   content: "Nigeria",
        // },
        // meta9: {
        //   property: "product:plural_title",
        //   content:
        //     "Vehicle's Fuel to CNG Conversion and Refilling Stationseria",
        // },
      },
    },

    service_page: {
      title:
        "Scimitar Hindustan – CNG Refuelling Station and  Install CNG Conversion kits in vehicles",
      meta: {
        meta1: {
          name: "description",
          content:
            "Install Rolling Energy CNG Conversion kits in domestic, commercial vehicles, industrial vehicles, and automotive applications in Nigeria",
        },
        // meta2: {
        //   name: "robots",
        //   content: "index,follow",
        // },
        // meta3: {
        //   property: "og:type",
        //   content: "business.business",
        // },
        // meta4: {
        //   property: "og:title",
        //   content:
        //     "Rolling Energy – CNG Refuelling Station and  Install CNG Conversion kits in vehicles",
        // },
        // meta5: {
        //   property: "og:image",
        //   content:
        //     "https://www.rollingenergyltd.com/static/media/person-service.32a1c71919e61ad7f877.jpg",
        // },
        // meta6: {
        //   property: "og:description",
        //   content:
        //     "Install Rolling Energy CNG Conversion kits in domestic, commercial vehicles, industrial vehicles, and automotive applications in Nigeria",
        // },
        // meta7: {
        //   property: "og:url",
        //   content: "https://www.rollingenergyltd.com/services",
        // },
        // meta8: {
        //   property: "business:contact_data:country_name",
        //   content: "Nigeria",
        // },
        // meta9: {
        //   property: "product:plural_title",
        //   content:
        //     "Vehicle's Fuel to CNG Conversion and Refilling Stationseria",
        // },
      },
    },
    career_page: {
      title:
        "Careers at Scimitar Automation - Discover Opportunities, Insights & Events",
      meta: {
        meta1: {
          name: "description",
          content:
            "Rolling Energy - Learn More through Rolling Energy Article, News, Events",
        },
        // meta2: {
        //   name: "robots",
        //   content: "index,follow",
        // },
        // meta3: {
        //   property: "og:type",
        //   content: "business.business",
        // },
        // meta4: {
        //   property: "og:title",
        //   content:
        //     "Rolling Energy - Learn More through Rolling Energy Article, News, Events",
        // },
        // meta5: {
        //   property: "og:image",
        //   content:
        //     "https://www.rollingenergyltd.com/static/media/person-service.32a1c71919e61ad7f877.jpg",
        // },
        // meta6: {
        //   property: "og:description",
        //   content:
        //     "Rolling Energy - Learn More through Rolling Energy Article, News, Events. Install Rolling Energy CNG Conversion kits in domestic, commercial vehicles, industrial vehicles, and automotive applications in Nigeria",
        // },
        // meta7: {
        //   property: "og:url",
        //   content: "https://www.rollingenergyltd.com/media",
        // },
        // meta8: {
        //   property: "business:contact_data:country_name",
        //   content: "Nigeria",
        // },
        // meta9: {
        //   property: "product:plural_title",
        //   content:
        //     "Vehicle's Fuel to CNG Conversion and Refilling Stationseria",
        // },
      },
    },
    contact_page: {
      title:
        " Contact Us at Scimitar Automation - Your Source for News and Insights",
      content:
        "Connect with Rolling Energy for expert CNG conversion services in Nigeria, backed by government support, and drive towards a sustainable, clean energy future.",

      meta: {
        meta1: {
          name: "description",
          content:
            "Connect with Rolling Energy for expert CNG conversion services in Nigeria, backed by government support, and drive towards a sustainable, clean energy future.",
        },
        // meta2: {
        //   name: "robots",
        //   content: "index,follow",
        // },
        // meta3: {
        //   property: "og:type",
        //   content: "website",
        // },
        // meta4: {
        //   property: "og:title",
        //   content:
        //     "Rolling Energy - Contact Rolling Energy to find more about CNG Products",
        // },
        // meta5: {
        //   property: "og:image",
        //   content:
        //     "https://www.rollingenergyltd.com/static/media/person-service.32a1c71919e61ad7f877.jpg",
        // },
        // meta6: {
        //   property: "og:description",
        //   content:
        //     "Connect with Rolling Energy for expert CNG conversion services in Nigeria, backed by government support, and drive towards a sustainable, clean energy future.",
        // },
        // meta7: {
        //   property: "og:url",
        //   content: "https://www.rollingenergyltd.com/contact-us",
        // },
        // meta8: {
        //   property: "business:contact_data:country_name",
        //   content: "Nigeria",
        // },
        // meta9: {
        //   property: "product:plural_title",
        //   content:
        //     "Vehicle's Fuel to CNG Conversion and Refilling Stationseria",
        // },
      },
    },
    cookieContent:
      "We use our own cookies for the use of the rolling energy site, personalize content and perform analyses of our traffic. We also share information about your use of our site with analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.",
  },
  {
    news_data: {
      news1: {
        youtube_embed:
          "https://www.youtube.com/embed/1I8TRLX4FSU?si=GV9OO58AQIMUvaQb",
        title: "FG Launches CNG Vehicle Conversion Centre In Kaduna State",
        content:
          "The Federal Government has inaugurated a Presidential Compressed Natural Gas Initiative Conversion Center in Kaduna State.",
        link: "https://www.youtube.com/watch?v=1I8TRLX4FSU",
      },
      news2: {
        youtube_embed:
          "https://www.youtube.com/embed/6los64VTOSU?si=CqAp0vWUWQhEbx25",
        title: "CNG Initiative to Create 50,000 Jobs in 6Month",
        content:
          "Nigeria begins Journey of transition into gas power economy. Presidential initiative to convert 1 million gas-powered cars and 50,000 Jobs.",
        link: "https://www.youtube.com/watch?v=6los64VTOSU",
      },
    },
  },

  {
    articles_page: {
      articles1: {
        img: "https://dailytrust.com/wp-content/uploads/2023/07/CNG.jpg",
        title: "CNG Conversion Centre To Redefine Transportation In Kaduna",
        content:
          "Nigeria is definitely taking a significant step forward with the government’s drive, under the visionary leadership of President Bola Ahmed Tinubu, for transitioning to a more affordable yet efficient alternative energy.",
        link: "https://dailytrust.com/cng-conversion-centre-to-redefine-transportation-in-kaduna/",
      },
      articles2: {
        img: "https://i0.wp.com/blueprint.ng/wp-content/uploads/2023/11/IMG_20231104_150135-scaled.jpg?resize=678%2C381&ssl=1",
        title:
          "Nigeria’s gas deposit can power industries, transport for 97 years – FG",
        content:
          "The Federal Government has unveiled the first Compressed Natural Gas (CNG) Conversion Centre in the North-west, while stating that Nigeria’s huge gas deposit can power the nation’s transportation and industrial sectors for 97 years, at a cheaper rate than petrol.",
        link: "https://blueprint.ng/nigerias-gas-deposit-can-power-industries-transport-for-97-years-fg/",
      },
      articles3: {
        img: "https://cdn.vanguardngr.com/wp-content/uploads/2023/11/image-59.png",
        title:
          "FG unveils CNG conversion centre in Kaduna, targets 1 million vehicles before 2027",
        content:
          "The Federal Government has unveiled the first Compressed Natural Gas (CNG) conversion centre in Kaduna, saying President Bola Ahmed Tinubu has a target of having a million out of about 17million vehicles on Nigeria",
        link: "https://www.vanguardngr.com/2023/11/fg-unveils-cng-conversion-centre-in-kaduna-targets-1-million-vehicles-before-2027/",
      },
    },
  },
];

export default data;
