function Maintenance_Support() {
  return (
    <>
      {/* banner start */}
      <div
        className="container-fluid MaintenanceSupport_page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">
            Maintenance & Support
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Pages</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Maintenance & Support
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* banner end */}

      {/* Ensuring the Longevity and Efficiency of Your Automation Systems
 start */}

      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h1 className="text-dark">
              Ensuring the Longevity and Efficiency of Your Automation Systems
            </h1>
            <p className="">
              At Scimitar Hindustan, we understand that the key to maximizing
              the value of your automation and control systems lies in proper
              maintenance and ongoing support. Our comprehensive Maintenance and
              Support services ensure that your systems operate at peak
              performance, minimizing downtime, and extending the life of your
              equipment. With preventive maintenance, real-time troubleshooting,
              and expert technical support, we keep your operations running
              smoothly and efficiently.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/project-2.jpg")}
            ></img>
          </div>
        </div>
      </div>

      {/* Ensuring the Longevity and Efficiency of Your Automation Systems
 end */}

      <div className="container mt-5 mb-5">
        <h1 className="text-warning text-center">
          Our Maintenance and Support Services
        </h1>
      </div>

      {/* ⦁	Preventive Maintenance:
 start */}

      <div className="container mb-5">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-12">
            <h1 className="">Preventive Maintenance</h1>
            <p className="">
              We offer scheduled maintenance programs designed to prevent
              unexpected breakdowns and extend the life of your automation and
              control systems. By regularly inspecting and maintaining
              equipment, we identify potential issues before they escalate,
              ensuring uninterrupted operations.
            </p>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center ">
                    <p className="mt-2">Regular system inspections</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">Calibration of sensors, actuators</p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">Software updates and patch</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">
                      Cleaning and lubrication of mechanical
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-12 col-md-12 col-sm-6">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">
                      Early detection of wear and tear to avoid costly repairs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/project-4.jpg")}
            ></img>
          </div>
        </div>
      </div>

      {/* Preventive Maintenance:
 end */}

      {/* Maximize the Performance of Your Automation Systems
start */}

      <div className="container-fluid mt-5 mb-5 footer_container_1 p-5">
        <h1 className="text-warning text-center">
          Maximize the Performance of Your Automation Systems
        </h1>

        <div className="container text-white mt-5">
          <div className="row">
            <div className="col-lg-7  ">
              <p className="">
                To keep your automation systems running at peak performance,
                trust Scimitar Hindustan for reliable maintenance and support
                services. Contact us today to learn more about our tailored
                solutions and how we can help you achieve maximum uptime and
                efficiency.
              </p>
            </div>

            <div className="col-lg-5 ">
              <img
                className=""
                src={require("../assets/img/Home/project-6.jpg")}
                style={{ width: "400px", height: "200px" }}
              ></img>
            </div>
          </div>
        </div>
      </div>

      {/* Maximize the Performance of Your Automation Systems
end */}

      {/* 24/7 Remote Monitoring and Support:
 start */}

      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/project-4.jpg")}
            ></img>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12">
            <h1 className="">24/7 Remote Monitoring and Support </h1>
            <p className="">
              Our team provides round-the-clock monitoring of your systems to
              detect any anomalies or performance issues in real-time. With
              remote access to your systems, we can troubleshoot and resolve
              many issues without the need for on-site visits, minimizing
              downtime.
            </p>
            <div className="container">
              <div className=" ">
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mx-2 "
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Real-time system monitoring for instant issue detection
                </h6>
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Remote diagnostics and troubleshooting
                </h6>
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Immediate alerts and proactive issue resolution
                </h6>
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Remote software updates and configuration changes
                </h6>
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Minimization of downtime and operational disruption
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ⦁	24/7 Remote Monitoring and Support:
 end */}

      {/* ⦁	Emergency Repair Services starts */}

      <div className="container mt-5 mb-5">
        <h1 className=" text-center">Emergency Repair Services</h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="card bg-secondary text-white p-5">
              <div className="text-center h3 advanced_section_icon">
                <i class="fa-solid fa-circle-check h1 text-warning"></i>
              </div>
              <p className=" mt-5">
                24/7 emergency response teams are specialized units
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="card p-5">
              <div className="text-center h3 advanced_section_icon">
                <i class="fa-solid fa-circle-check  h1 text-warning"></i>
              </div>
              <p className=" mt-5">
                Fast troubleshooting and diagnosis of system failures
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="card bg-secondary text-white p-5">
              <div className="text-center h3 advanced_section_icon ">
                <i class="fa-solid fa-circle-check h1 text-warning"></i>
              </div>
              <p className=" mt-5">
                On-site repair and replacement of faulty components
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="card p-5">
              <div className="text-center h3 advanced_section_icon">
                <i class="fa-solid fa-circle-check h1 text-warning"></i>
              </div>
              <p className=" mt-5">
                Minimal downtime and fast recovery of operations
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* ⦁	Emergency Repair Services end */}

      {/* ⦁	System Optimization and Upgrades start */}

      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-12">
            <h1 className="">System Optimization and Upgrades </h1>
            <p className="">
              Over time, automation systems may require optimization or upgrades
              to keep up with changing technology and production demands. We
              offer system performance evaluations and recommend necessary
              upgrades to improve efficiency, reduce energy consumption, and
              ensure long-term system reliability.
            </p>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">System performance evaluation</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">Software and hardware upgrades</p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">Retrofitting of outdated components</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">Integration of new technologies</p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-12 col-md-12 col-sm-6">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">
                      Recommendations for energy savings and cost reduction
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/project-4.jpg")}
            ></img>
          </div>
        </div>
      </div>

      {/* ⦁	System Optimization and Upgrades end */}

      {/* Why Choose [Your Company Name] for Control System Design?
start */}

      <div className="container-fluid footer_container_1 p-5 mb-5">
        <div className="container mt-5 mb-5">
          <h1 className="text-warning text-center">
            Why Choose Scimitar Hindustan for Control System Design?
          </h1>
        </div>

        <div className="row p-5">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="row">
              <div className="col-lg-2 text-end">
                <i class="fa-solid fa-house h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b>Expert Team: </b>Our technicians and engineers have years
                  of experience in industrial automation and control systems,
                  providing expert support for even the most complex systems.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="row">
              <div className="col-lg-2 text-end ">
                {/* <i class="fa-solid fa-house h1 text-warning"></i> */}
                {/* <i class="fa-solid fa-chart-simple h1 text-warning"></i> */}
                <i class="fa-solid fa-chart-line h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b> 24/7 Availability: </b>We offer round-the-clock support to
                  ensure that your systems receive immediate attention when an
                  issue arises.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-5">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="row">
              <div className="col-lg-2 text-end ">
                {/* <i class="fa-solid fa-house h1 text-warning"></i> */}
                <i class="fa-solid fa-shield h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b>Tailored Services: </b>We customize our maintenance plans
                  to fit your unique operational requirements, ensuring your
                  systems receive the care they need when they need it.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="row">
              <div className="col-lg-2 text-end">
                {/* <i class="fa-solid fa-house h1 text-warning"></i> */}
                <i class="fa-solid fa-star h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b>Long-Term Partnership: </b>We view our maintenance and
                  support services as a partnership. Our goal is to ensure your
                  systems continue to perform efficiently, providing value to
                  your business for the long term.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose [Your Company Name] for Control System Design?
end */}

      {/* Benefits of Advanced Control System Design:
start */}

      <div className="container mt-5 mb-5">
        <h1 className="text-dark text-center">
          Benefits of Our Maintenance and Support Services
        </h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card p-4 bg-secondary ">
              <h4 className="text-warning"> Reduced Downtime </h4>
              <p className="">
                With regular maintenance and real-time monitoring, we catch
                issues before they become serious, ensuring minimal downtime and
                keeping your operations .
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card p-4 bg-secondary">
              <h4 className="text-warning"> Cost Savings</h4>
              <p className="">
                Advanced control systems provide precise management of
                machinery, ensuring consistent product quality and minimizing
                errors.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card p-4 bg-secondary">
              <h4 className="text-warning">Increased Lifespan</h4>
              <p className="">
                Regular maintenance and system optimization ensure that your
                automation systems continue to perform at peak levels,
                prolonging their lifespan
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          {/* <div className="col-lg-2 col-md-2 col-sm-2"></div> */}
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card p-4  bg-secondary">
              <h4 className="text-warning"> Improved System Efficiency</h4>
              <p className="">
                Our optimization services help you maintain high efficiency,
                reduce energy consumption, and boost productivity.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card p-4  bg-secondary">
              <h4 className="text-warning"> Fast Issue Resolution</h4>
              <p className="">
                Our 24/7 support and remote monitoring services allow us to
                detect and resolve issues quickly, minimizing the impact on your
                production schedules.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card p-4 text-center bg-secondary">
              <h4 className="text-warning"> Customized Maintenance</h4>
              <p className="">
                We tailor our maintenance programs to fit your unique
                operational needs, ensuring that your systems receive the care
                they need without unnecessary service.
              </p>
            </div>
          </div>
          {/* <div className="col-lg-2 col-md-2 col-sm-2"></div> */}
        </div>
      </div>

      {/* Benefits of Advanced Control System Design:
end */}
    </>
  );
}

export default Maintenance_Support;
