import "../assets/css/Industrial_Robotics.css";
function Industrial_Robotics() {
  return (
    <>
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">
            Industrial Robotics
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Products</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Industrial Robotics
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 p-3 ">
            <h2 className="text-warning">
              Transforming Industries with Advanced Robotic Solutions
            </h2>
            <p>
              {" "}
              At Scimitar Hindustan, we specialize in delivering cutting-edge
              industrial robotics solutions designed to enhance efficiency,
              precision, and productivity across a wide range of industries. Our
              advanced robotics systems are engineered to automate complex
              tasks, reduce operational costs, and boost overall production
              capacity. From assembly lines to material handling, our industrial
              robots bring unparalleled accuracy and reliability to your
              operations
            </p>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="row">
              <div className="col-lg-6">
                {" "}
                <img
                  className="Transforming1"
                  src={require("../assets/img/products/IndustrialRobotics/Robotic Solutions.jpg")}
                ></img>
              </div>
              <div className="col-lg-6">
                {" "}
                <img
                  className="Transforming2"
                  src={require("../assets/img/products/IndustrialRobotics/Robotic Solutions2.jpg")}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <h1 className="text-center text-warning  pb-2">
          {" "}
          Our Industrial Robotics Solutions
        </h1>

        <div class="container-fluid my-5">
          {/* <!-- Robotic Arms Section --> */}
          <div class="row align-items-center mb-5">
            <div class="col-md-6 text-center">
              <img
                src={require("../assets/img/products/IndustrialRobotics/Robotic Arms.jpg")}
                alt="Robotic Arms"
                class="img-fluid rounded"
              />
            </div>
            <div class="col-md-6">
              <h2 class="mb-3 text-warning">Robotic Arms</h2>
              <p>
                Our versatile robotic arms are ideal for welding, painting,
                assembly, and packaging. With high-speed operation and precision
                control, robotic arms streamline repetitive tasks.
              </p>
              <ul>
                <p>
                  <i class="fa-solid fa-arrow-right mx-3"></i>Multi-axis
                  flexibility for complex tasks
                </p>
                <p>
                  <i class="fa-solid fa-arrow-right   mx-3"></i>High-speed,
                  high-precision movements
                </p>
                <p>
                  {" "}
                  <i class="fa-solid fa-arrow-right  mx-3"></i>Payload handling
                  from light to heavy-duty operations
                </p>
                <p>
                  {" "}
                  <i class="fa-solid fa-arrow-right  mx-3"></i>Advanced safety
                  features for human-robot collaboration
                </p>
                <p>
                  <i class="fa-solid fa-arrow-right  mx-3"></i>Easily
                  programmable for various tasks
                </p>
              </ul>
            </div>
          </div>

          <div className="row align-items-center mb-5">
            <div className="col-lg-12">
              <div className="row">
                {/* Key Features List */}
                <div className="col-lg-4 "></div>

                {/* Image and Text Section */}
                <div className="col-lg-12 card AGVs-overall ">
                  <div className="row">
                    <div className="col-lg-6 ">
                      <p>
                        <h2 className="text-warning ">
                          Automated Guided Vehicles (AGVs)
                        </h2>
                        <br />
                        Our AGVs are designed for smart material handling,
                        transportation, and logistics within manufacturing
                        environments. AGVs reduce the need for human
                        intervention, ensuring efficient and accurate
                        transportation of materials and goods throughout the
                        production facility.
                      </p>
                      <p className="   w-100">
                        Autonomous navigation with collision detection
                      </p>
                      <p className="  w-100">
                        Real-time route optimization for efficient material flow
                      </p>
                      <p className="  w-100">
                        Customizable for different payload sizes
                      </p>
                      <p className="   w-100">
                        Low maintenance and energy-efficient operations
                      </p>
                    </div>
                    <div className="col-lg-6  ">
                      <img
                        src={require("../assets/img/products/IndustrialRobotics/Automated Guided Vehicles.jpg")}
                        alt="AGVs"
                        className="AGVs-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Cobots Section --> */}
          <div div className="row align-items-center mb-5">
            <div className="col-md-6">
              <img
                className="Cobots-imgs"
                src={require("../assets/img/products/IndustrialRobotics/istockphoto-1475054812-612x612.jpg")}
              />
            </div>
            <div className="col-md-6 card p-3 ">
              <h2 className="mb-3 text-warning">
                Collaborative Robots (Cobots)
              </h2>
              <p>
                Cobots work safely alongside human operators to automate
                flexible, adaptable, and precise tasks. They are user-friendly,
                easy to program, and cost-effective for small businesses.
              </p>
              <div className="">
                <p>Safe operation with real-time safety monitoring</p>
                <p>Quick deployment with intuitive programming</p>
                <p>Adaptable for different applications</p>
                <p>Lightweight and mobile for easy relocation</p>
                <p>Cost-effective automation for SMEs</p>
              </div>
            </div>
          </div>

          {/* <!-- Pick and Place Robots Section --> */}
          <div div class="row align-items-center mb-5">
            <div class="col-md-12 text-center"></div>
            <div class="col-md-12  bg-dark  p-5 d-flex justify-content-center mt-4 position-relative">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card primary-img ">
                    <div className="row ">
                      <div className="col-lg-12">
                        {" "}
                        <h2 className="text-center text-warning">
                          Pick and Place Robots
                        </h2>{" "}
                      </div>
                      <div className="col-lg-12">
                        {" "}
                        <p>
                          Pick and place robots offer ultra-fast, precise
                          movement, ideal for industries like food, electronics,
                          and pharmaceuticals. These robots reduce manual labor
                          and improve product handling accuracy.
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className=" card secondary-img">
                    <div className="row">
                      <div className="col-lg-12">
                        <h4 className="text-center text-dark">Key Features</h4>
                      </div>
                      <div className="col-lg-12">
                        <p className="text-center">
                          High-speed picking, sorting, and placing
                        </p>
                        <p className="text-center">
                          Accurate object identification with vision systems
                        </p>
                        <p className="text-center">
                          Integration with conveyors and packaging systems
                        </p>
                        <p className="text-center">
                          Multi-object handling with precision and speed
                        </p>
                        <p className="text-center">
                          Reduced operational costs and improved throughput
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="Roboticsbgimg ">
            <div className="row Cobots-overall ">
              <div className="col-lg-12 pt-3">
                <div className="card Robotics-card ">
                  <h3 className="text-warning text-center">
                    Benefits of Industrial Robotics
                  </h3>
                </div>
              </div>
              <div className="col-lg-6 pt-3">
                <div className="card Robotics-card  ">
                  <p className="text-light">
                    Increased Productivity: Operate 24/7, boosting output.
                    <br />
                    Enhanced Precision and Quality: High accuracy and reduced
                    errors.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 pt-3">
                <div className="card Robotics-card   ">
                  <p className="text-light">
                    Improved Safety: Handles hazardous tasks safely.
                    <br />
                    Scalability and Flexibility: Easily adapt to production
                    needs.
                  </p>
                </div>
              </div>
              <div className="col-lg-12 pt-3">
                <div className="card Robotics-card ">
                  <h3 className="text-warning text-center">
                    Industries We Serve
                  </h3>
                </div>
              </div>
              <div className="col-lg-6 pt-3">
                <div className="card Robotics-card  ">
                  <p className="text-light">
                    Automotive: Automated welding, painting, and assembly line
                    operations<br></br>
                    Manufacturing: Material handling, assembly, and packaging
                    processes.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 pt-3">
                <div className="card Robotics-card  ">
                  <p className="text-light">
                    Food and Beverage: Precision sorting, packaging, and
                    palletizing tasks.
                    <br />
                    Pharmaceutical: Automated dispensing, packaging, and quality
                    control.
                  </p>
                </div>
              </div>
              <div className="col-lg-12 pt-3">
                <div className="card Robotics-card ">
                  <p className="text-light text-center">
                    Electronics: Handling delicate components with precision and
                    care.
                    <br></br>Logistics & Warehousing: AGVs for material
                    transport and inventory management
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Why Choose Us Section --> */}
        </div>
      </div>

      <div className="card container bg-warning">
        <div className="row ">
          <div className="col-lg-4 text-start">
            <img
              src={require("../assets/img/products/IndustrialRobotics/whyChoose.jpg")}
              className="img-fluid"
              style={{
                marginLeft: "-12px",
                borderRadius: "5px 15px 500px 15px",
              }}
              alt="Expertise in Automation"
            />
          </div>
          <div className="col-lg-8 p-5">
            <h2 className="text-light">
              Why Choose Scimitae Hindustan for Industrial Robotics?
            </h2>

            <div className="mb-3">
              <h4 className="text-light">
                <i className="fa-solid fa-hand-point-right text-dark"></i>{" "}
                &nbsp; Expertise in Automation
              </h4>
              <p>
                With decades of experience, we offer reliable, customized
                robotics solutions designed to meet your specific industrial
                needs.
              </p>
            </div>

            <div className="mb-3">
              <h4 className="text-light">
                <i className="fa-solid fa-hand-point-right   text-dark"></i>{" "}
                &nbsp; Innovative Technologies
              </h4>
              <p>
                We stay at the forefront of robotics technology, incorporating
                AI, machine learning, and Industry 4.0 advancements into our
                solutions.
              </p>
            </div>

            <div className="mb-3">
              <h4 className="text-light">
                <i className="fa-solid fa-hand-point-right  text-dark"></i>{" "}
                &nbsp; End-to-End Support
              </h4>
              <p>
                From design and installation to maintenance and training, we
                provide full lifecycle support to ensure smooth implementation
                and operation.
              </p>
            </div>

            <div className="mb-3">
              <h4 className="text-light">
                <i className="fa-solid fa-hand-point-right text-dark"></i>{" "}
                &nbsp;Sustainability and Efficiency
              </h4>
              <p>
                Our robots are energy-efficient and designed to maximize
                productivity.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="p-5 text-center">
          <div className="col-lg-12 ">
            <h6> Industrial Robotics</h6>
          </div>
          <div className="col-lg-12">
            <h1 className="text-warning">
              Get Started with Industrial Robotics Today
            </h1>
          </div>
          <div className="col-lg-12  Cobots-pasage ">
            <p>
              Looking to automate your operations and improve efficiency?
              Contact Scimitar Hindustan today for a consultation, and let’s
              design the perfect robotics solution for your business.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Industrial_Robotics;
