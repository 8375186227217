import { useEffect, useState } from "react";
import "../assets/css/AutomationControllers.css";
import ScimitarLogo from "../assets/img/scimatar logo 2.png"; // Import the image
import AOS from "aos";
import "aos/dist/aos.css";
import data from "../../src/component/ApiUrl/meta_api";

function AutomationControllers() {
  const [meta, setMeta] = useState(null);

  const fetchData = () => {
    const ContactMeta = data[0]?.contact_page;
    if (ContactMeta) {
      setMeta(ContactMeta);
    }
  };

  useEffect(() => {
    fetchData();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);
  const benefits = [
    {
      title: "Increased Efficiency",
      description:
        "Automate repetitive tasks and processes to maximize production output with minimal human intervention",
      icon: "fas fa-chart-line", // You can use font-awesome for icons
    },
    {
      title: "Improved Accuracy",
      description:
        "Precise control of machinery ensures consistent product quality and reduces errors.",
      icon: "fas fa-check-circle",
    },
    {
      title: "Cost Reduction",
      description:
        "By optimizing processes and reducing downtime, our controllers help lower operational costs.",
      icon: "fas fa-shield-alt",
    },
    {
      title: "Enhanced Safety",
      description:
        "Automation controllers reduce human interaction with hazardous environments, improving safety.",
      icon: "fas fa-dollar-sign",
    },
    {
      title: "Remote Monitoring & Diagnostics",
      description:
        "Stay connected to your systems from anywhere, ensuring real-time insights and timely maintenance.",
      icon: "fas fa-expand-arrows-alt",
    },
  ];
  const industries = [
    {
      title: "Automotive",
      description: "Automated welding, painting, and assembly line operations.",
      icon: ScimitarLogo, // Example image, replace with actual file
    },
    {
      title: "Manufacturing",
      description: "Material handling, assembly, and packaging processes.",
      icon: "manufacturing.jpg",
    },
    {
      title: "Food and Beverage",
      description: "Precision sorting, packaging, and palletizing tasks.",
      icon: "food_beverage.jpg",
    },
    {
      title: "Pharmaceutical",
      description: "Automated dispensing, packaging, and quality control.",
      icon: "pharmaceutical.jpg",
    },
    {
      title: "Electronics",
      description: "Handling delicate components with precision and care.",
      icon: "electronics.jpg",
    },
    {
      title: "Logistics & Warehousing",
      description: "AGVs for material transport and inventory management.",
      icon: "logistics.jpg",
    },
  ];

  return (
    <>
      {/* banner starts */}
      <div
        className="container-fluid AutomationControllers_page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">
            Automation Controllers
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Products</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Automation Controllers
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* banner ends */}

      <div className="industrial-overview">
        {/* Banner Section */}

        {/* Content Section */}
        <div className="container  pt-3 pb-5">
          <div className="row" data-aos="fade-left">
            <div className="col-lg-6 ">
              <img
                src={require("../assets/img/products/AutomationControllers/controllersincludes.jpg")}
                alt="Industrial Equipment"
                className="Automationimg"
              />
            </div>
            <div className="col-lg-6 card p-3  " style={{}}>
              <h2 className="text-warning">Automation Controllers</h2>
              <h4>
                Empowering Industrial Automation with Advanced Control Solutions
              </h4>
              <p>
                At Scimitar Hindustan, we provide cutting-edge automation
                controllers designed to streamline industrial operations,
                optimize processes, and ensure precise control. Our range of
                automation controllers, including Programmable Logic Controllers
                (PLCs) and Human-Machine Interfaces (HMIs), enable seamless
                communication between machines and systems, helping industries
                operate more efficiently and with greater flexibility.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" Controllers-Include ">
        <div className="row">
          <div className="col-lg-3">
            <h4 className="text-warning mb-3">
              Our Automation Controllers Include
            </h4>

            <div className="card Include-card ">
              <h4>Programmable Logic Controllers (PLCs)</h4>
              <p>
                Our PLCs offer robust, scalable solutions for a variety of
                industrial applications. Designed for high-performance
                automation, they deliver real-time control and data acquisition,
                allowing for precision in manufacturing, assembly lines, and
                complex industrial processes.
              </p>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-12">
                <img
                  src={require("../assets/img/products/AutomationControllers/pexels-photo-257699.webp")}
                  alt="Industrial Equipment"
                  className="Automationimg"
                />
              </div>
              <div className="col-lg-12 pt-3">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-2">
                        {" "}
                        <i className="fa-solid fa-star"></i>
                      </div>
                      <div className="col-lg-10">
                        {" "}
                        High-speed processing and real-time control
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-2">
                        {" "}
                        <i className="fa-solid fa-star"></i>
                      </div>
                      <div className="col-lg-10">
                        {" "}
                        Seamless integration with other automation systems
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-2">
                        {" "}
                        <i className="fa-solid fa-star"></i>
                      </div>
                      <div className="col-lg-10">
                        {" "}
                        Reliable performance in harsh industrial environments
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-2">
                        {" "}
                        <i className="fa-solid fa-star"></i>
                      </div>
                      <div className="col-lg-10">
                        {" "}
                        Modular and scalable designs are approaches
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-2">
                        {" "}
                        <i className="fa-solid fa-star"></i>
                      </div>
                      <div className="col-lg-10">
                        {" "}
                        Remote monitoring and control capabilities
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    {/* You can add another item here or leave it empty */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="row">
          <div className="col-lg-12 col-md-12 p-5">
            <h4 className="text-warning text-center">
              Human-Machine Interfaces (HMIs)
            </h4>
            <p className=" text-center Human-Machinepasage">
              {" "}
              Our user-friendly HMIs ensure easy interaction between operators
              and machinery. With intuitive interfaces, graphical displays, and
              remote accessibility, our HMIs simplify monitoring and control,
              improving overall productivity and safety in your operations.
            </p>
          </div>

          <div className="  Human-Machine-card mt-4">
            <div className="row">
              <div className=" col-lg-2 col-md-4">
                <div className="card Human-Machine-cardess " data-aos="fade-up">
                  <img
                    src={require("../assets/img/images (16).jpg")}
                    className="card-img-top"
                    alt={`Card `}
                  />
                  <div className="cardbodysize">
                    <p className="card-text">
                      Touchscreen interfaces operation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div
                  className="card Human-Machine-cardess"
                  data-aos="fade-down"
                >
                  <img
                    src={require("../assets/img/images (13).jpg")}
                    className="card-img-top"
                    alt={`Card `}
                  />
                  <div className=" cardbodysize">
                    <p className="card-text">real-time data visualization.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="card Human-Machine-cardess" data-aos="fade-up">
                  <img
                    src={require("../assets/img/images (14).jpg")}
                    className="card-img-top"
                    alt={`Card `}
                  />
                  <div className="cardbodysize">
                    <p className="card-text">
                      support for global applications.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-2" >
                <div className="card">
                  <img src="https://via.placeholder.com/150" className="card-img-top" alt={`Card `} />
                  <div className="card-body">
                    <h5 className="card-title">Remote access for troubleshooting and monitoring. </h5>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-2 col-md-6 pt-2">
                <div
                  className="card Human-Machine-cardess"
                  data-aos="fade-down"
                >
                  <img
                    src={require("../assets/img/images (15).jpg")}
                    className="card-img-top"
                    alt={`Card `}
                  />
                  <div className="cardbodysize">
                    <p className="card-text">
                      Remote access for troubleshooting
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 pt-2">
                <div className="card Human-Machine-cardess" data-aos="fade-up">
                  <img
                    src={require("../assets/img/products/AutomationControllers/alert-timing.jpg")}
                    className="card-img-top"
                    alt={`Card `}
                  />
                  <div className="cardbodysize">
                    <p className="card-text">
                      Integrated alarms and notifications
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Cobotsbgimg">
        <div className="row">
          <div className="p-3 text-center">
            <div className="col-lg-12 ">
              <h6> DCS working of function</h6>
            </div>
            <div className="col-lg-12">
              <h1 className="text-warning">Distributed Control Systems</h1>
            </div>
            <div className="col-lg-12  Cobots-pasage ">
              <p className="p-1">
                For complex and large-scale industrial environments, our
                Distributed Control Systems (DCS) provide a centralized control
                structure. DCS ensures continuous, automated control of plant
                processes with redundancy and fault-tolerant architecture to
                minimize downtime..
              </p>
            </div>
          </div>
        </div>
        <div className="row Cobots-overall ">
          <div className="col-lg-4">
            <div className="card Cobots-card ">
              <p>
                Scalable system architecture for plant-wide control<br></br>
                Redundancy options for uninterrupted operations
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card Cobots-card ">
              <p>Integration with SCADA systems</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card Cobots-card ">
              <p>
                Advanced process control algorithms<br></br>
                Real-time monitoring and analytics
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <h1 className="text-center text-warning mb-4">
          Benefits of Our Automation Controllers
        </h1>
        <div className="row text-center" data-aos="fade-down">
          {benefits.map((benefit, index) => (
            <div
              className={`${index < 3 ? "col-lg-4" : "col-lg-6"} col-md-6 mb-4`} // 3 columns in the first row, 2 columns in the second row
              key={index}
            >
              <div className="card shadow-sm">
                <div className="card-body card-body-Benefits">
                  <i className={`${benefit.icon} fa-3x mb-3`}></i> {/* Icon */}
                  <h5 className="card-title card-title-benefits">
                    {benefit.title}
                  </h5>
                  <p className="card-text card-text-benefits">
                    {benefit.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className=" bg-warning p-3 ">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6 mb-4" data-aos="fade-right">
              {" "}
              {/* Two columns in a row */}
              <img
                src={`${require("../assets/img/products/AutomationControllers/IndustriesWeServe.jpg")}`}
                className="card-img-top"
                alt={"lll"}
              />
            </div>
            <div className="col-lg-6 mb-4" data-aos="fade-up">
              <h2 className=" mb-4">Industries We Serve</h2>

              {industries.map((industry, index) => (
                <div className=" mb-4" key={index}>
                  {" "}
                  {/* Two columns in a row */}
                  <div className="card ">
                    <div className="card-body">
                      <h5 className="card-title">{industry.title}</h5>
                      <p className="card-text">{industry.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className=" why-choose-us-bg ">
        <div className="container why-choose-us mt-5">
          <h2 className="text-center mb-4 text-warning">
            Why Choose Scimitar Hindustan for Industrial Robotics?
          </h2>
          <div className="row">
            <div className="col-md-12 mb-4">
              <ul className="reasons-list">
                <li className="" data-aos="fade-left">
                  <h4>Expertise in Automation</h4>
                  <p>
                    With decades of experience, we offer reliable, customized
                    robotics solutions designed to meet your specific industrial
                    needs.
                  </p>
                </li>
                <li className="" data-aos="fade-right">
                  <h4>Innovative Technologies</h4>
                  <p>
                    We stay at the forefront of robotics technology,
                    incorporating AI, machine learning, and Industry 4.0
                    advancements into our solutions.
                  </p>
                </li>
                <li className="" data-aos="fade-left">
                  <h4>End-to-End Support</h4>
                  <p>
                    From design and installation to maintenance and training, we
                    provide full lifecycle support to ensure smooth
                    implementation and operation.
                  </p>
                </li>
                <li className="" data-aos="fade-right">
                  <h4>Sustainability and Efficiency</h4>
                  <p>
                    Our robots are energy-efficient and designed to maximize
                    output while minimizing resource usage.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="get-started text-center mt-5">
          <div className="row">
            <div className="col-lg-12">
              {" "}
              <h3 className="text-warning">
                Get Started with Industrial Robotics Today
              </h3>
              <br></br>
            </div>
            <div className="col-lg-12">
              <p className="get-started-pasage ">
                Looking to automate your operations and improve efficiency?
                Contact Scimitar Hindustan today for a consultation, and let’s
                design the perfect robotics solution for your business.
              </p>
            </div>
            <div className="col-lg-12">
              <button className="btn btn-warning rounded p-2">More</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AutomationControllers;
