import "../assets/css/MachineRetrofit.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import React, { useEffect } from "react";

function MachineRetrofit() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <>
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">
            Machine Retrofit
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">
                <a href="#">Pages</a>
              </li> */}
              <li className="breadcrumb-item active" aria-current="page">
                Machine Retrofit
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/*  */}

      <div className="container marginTB">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
            <img
              className=""
              src={require("../assets/img/Service/Machine Retrofit/retrofitting transforming.jpg")}
              style={{ width: "500px", height: "300px", borderRadius: "20px" }}
            ></img>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
            <p className="marginTB">
              At Scimitar Automation, we specialize in machine retrofitting
              transforming your existing equipment to meet modern standards of
              efficiency, safety, and performance. Our retrofit solutions
              breathe new life into your machinery, extending its lifespan while
              integrating the latest technology and features.
            </p>
          </div>
        </div>
      </div>

      {/*  */}

      {/*  */}

      <div className="container marginTB">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
            <h3 className="">Why Choose Machine Retrofit?</h3>
            <p className="">
              Retrofitting offers a cost-effective and sustainable way to
              enhance your machinery without the expense of new equipment. By
              updating existing machines, you can achieve significant
              performance improvements and extend their lifespan, all while
              saving money. Our retrofitting services enhance efficiency through
              advanced controls, modernize safety features for compliance, and
              provide custom upgrades tailored to your operational needs. With a
              focus on minimizing downtime and incorporating the latest
              automation technologies, we ensure a seamless process that boosts
              productivity. Plus, retrofitted equipment often has a higher
              resale value. If you’re ready to upgrade your machinery, contact
              us for a consultation and quote—we’re here to help you maximize
              your equipment’s potential!
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
            <img
              className=""
              src={require("../assets/img/Service/Machine Retrofit/Choose Machine Retrofit.jpg")}
              style={{
                width: "500px",
                height: "300px",
                marginTop: "20px",
                borderRadius: "20px",
              }}
            ></img>
          </div>
        </div>
      </div>
      {/*  */}

      {/*  */}

      <div className=" bg-dark  p-5  text-center marginTB">
        <h1 className="text-warning">Our Retrofit Process</h1>

        <div className="row mt-3">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div
              className="card shadow-lg w-100 h-100 bg-Retrofit text-center p-4"
              data-aos="fade-right"
            >
              <div className="">
                <h5 className="text-dark">Assessment</h5>
                <hr></hr>
                <p className="text-secondary">
                  Our team conducts a thorough evaluation of your existing
                  machinery to identify retrofit opportunities.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div
              className="card shadow-lg w-100 h-100 bg-Retrofit  text-center p-4"
              data-aos="fade-down"
            >
              <div className="">
                <h5 className="text-dark">Planning</h5>
                <hr></hr>
                <p className="text-secondary">
                  We collaborate with you to develop a customized retrofit plan
                  tailored to your needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div
              className="card shadow-lg w-100 h-100  bg-Retrofit text-center p-4"
              data-aos="fade-left"
            >
              <div className="">
                <h5 className="text-dark">Implementation</h5>
                <hr></hr>
                <p className="text-secondary">
                  Skilled technicians carry out the retrofit process with
                  minimal disruption to your operations.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-2"></div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div
              className="card shadow-lg w-100 h-100 bg-Retrofit text-center p-4"
              data-aos="fade-up"
            >
              <div className="">
                <h5 className="text-dark">Testing</h5>
                <hr></hr>
                <p className="text-secondary">
                  Rigorous testing ensures that your newly retrofitted machine
                  meets performance expectations.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div
              className="card shadow-lg w-100 h-100 bg-Retrofit text-center p-4"
              data-aos="fade-up"
            >
              <div className="">
                <h5 className="text-dark">Training and Support</h5>
                <hr></hr>
                <p className="text-secondary">
                  We provide training for your staff and ongoing support to
                  ensure seamless integration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  */}

      {/*  */}

      <div className="container text-center marginTB">
        <h1 className="text-warning">Industries We Serve</h1>
      </div>

      <div className="container marginTB">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
            <img
              className=""
              src={require("../assets/img/Service/Machine Retrofit/Scimitar Automation’s machine.jpg")}
              style={{
                width: "500px",
                height: "400px",
                borderRadius: "20px",
                objectFit: "cover",
              }}
            ></img>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
            <h3 className="">
              Scimitar Automation’s machine retrofit services cater to a variety
              of sectors, including:
            </h3>
            <div className="container ">
              <p className="">
                <i class="fa-regular fa-hand-point-right mx-3 text-warning"></i>
                Manufacturing
              </p>
              <p className="">
                <i class="fa-regular fa-hand-point-right mx-3 text-warning"></i>
                Automotive
              </p>
              <p className="">
                <i class="fa-regular fa-hand-point-right mx-3 text-warning"></i>
                Food and Beverage
              </p>
              <p className="">
                <i class="fa-regular fa-hand-point-right mx-3 text-warning"></i>
                Pharmaceuticals
              </p>
              <p className="">
                <i class="fa-regular fa-hand-point-right mx-3 text-warning"></i>
                Textiles
              </p>
              <p className="">
                <i class="fa-regular fa-hand-point-right mx-3 text-warning"></i>
                Packaging
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
    </>
  );
}
export default MachineRetrofit;
