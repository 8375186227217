import "../assets/css/EquipmentRepair.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import React, { useEffect } from "react";

function EquipmentRepair() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);
  return (
    <>
      {/* banner section start */}

      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">
            Hardware Services
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Services</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Hardware Services
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* banner section end */}

      {/* Introduction start */}

      <div className="container marginTB">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
            <h4 className="text-warning">At</h4>
            <h1 className="text-warning">Scimitar Hindustan,</h1>
            <p className="pasagejustify">
              we specialize in comprehensive industrial equipment repair
              services designed to keep your operations running smoothly. With
              years of experience and a dedicated team of skilled technicians,
              we are committed to providing timely and effective repairs for a
              wide range of industrial machinery.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
            <img
              src={require("../assets/img/Service/Hardware Services/we specialize.jpg")}
              className="Introduction_image_Equipment"
            ></img>
          </div>
        </div>
      </div>

      {/* Introduction end */}

      {/*  */}

      {/* <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <div
              className="card text-white p-3"
              style={{ backgroundColor: "#032556" }}
            >
              <div className="row p-3  ">
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <h5 className="">
                    We Offer Maintenance for all CNC machine brands
                  </h5>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <button className="btn btn-primary">Get a Quote</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <div
              className="card text-white p-3"
              style={{ backgroundColor: "#01142E" }}
            >
              <div className="row p-3  ">
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <h5 className="">
                    We Offer Maintenance for all CNC machine brands
                  </h5>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <button className="btn btn-primary">Get a Quote</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/*  */}

      {/* Quality You Can Trust
start */}

      <div className="container marginTB  text-white Quality_You_Can_Trust">
        <div className="row text-center">
          <div className="col-lg-12 col-md-12 col-sm-6 marginTB ">
            <h2 className="text-warning">
              Our commitment to quality is at the core of our repair services
            </h2>
            <p className="text-white text-center">
              We utilize state-of-the-art diagnostic tools and follow industry
              best practices to deliver effective and reliable repairs. Here are
              ten benefits of choosing Scimitar Automation for your industrial
              equipment repairs
            </p>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="card ten_card_sections" data-aos="fade-down">
                <div className="row text-center">
                  <div className="col-lg-12 col-md-12 col-sm-6 p-1">
                    <h1>
                      <i class="fa-solid fa-user"></i>
                    </h1>
                    <h5 className="Effective_cost text-warning">
                      Experienced Technicians
                    </h5>
                  </div>
                </div>
                <div className="">
                  <div className="text-center">
                    Our team has extensive knowledge and experience in various
                    industrial equipment.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="card ten_card_sections" data-aos="fade-up">
                <div className="row text-center">
                  <div className="col-lg-12 col-md-12 col-sm-6 p-1">
                    <h1>
                      <i class="fa-solid fa-clock"></i>{" "}
                    </h1>
                    <h5 className="Effective_cost text-warning">
                      Rapid Response Time
                    </h5>
                  </div>
                </div>
                <div className="overlay">
                  <div className="text-center">
                    We prioritize urgent repair needs to reduce your downtime
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="card ten_card_sections" data-aos="fade-down">
                <div className="row text-center">
                  <div className="col-lg-12 col-md-12 col-sm-6 p-1">
                    <h1>
                      <i class="fa-solid fa-screwdriver-wrench"></i>
                    </h1>
                    <h5 className="Effective_cost text-warning">OEM Parts</h5>
                  </div>
                </div>
                <div className="overlay">
                  <div className="text-center">
                    We use high-quality, original equipment manufacturer parts
                    for all repairs.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="card ten_card_sections" data-aos="fade-up">
                <div className="row text-center">
                  <div className="col-lg-12 col-md-12 col-sm-6 p-1">
                    <h1>
                      <i class="fa-solid fa-helmet-safety"></i>{" "}
                    </h1>
                    <h5 className="Effective_cost text-warning">
                      Preventive Maintenance
                    </h5>
                  </div>
                </div>
                <div className="overlay">
                  <div className="text-center">
                    Our services include preventive maintenance to extend
                    equipment lifespan.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="card ten_card_sections" data-aos="fade-down">
                <div className="row text-center">
                  <div className="col-lg-12 col-md-12 col-sm-6 p-1">
                    <h1>
                      <i class="fa-solid fa-thumbs-up"></i>
                    </h1>
                    <h5 className="Effective_cost text-warning">
                      Customized Solutions
                    </h5>
                  </div>
                </div>
                <div className="overlay">
                  <div className="text-center">
                    We tailor our repair strategies to fit your unique
                    operational needs.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="card ten_card_sections" data-aos="fade-up">
                <div className="row text-center">
                  <div className="col-lg-12 col-md-12 col-sm-6 p-1">
                    <h1>
                      <i class="fa-solid fa-hourglass-start"></i>
                    </h1>
                    <h5 className="Effective_cost text-warning">
                      24/7 Emergency Service
                    </h5>
                  </div>
                </div>
                <div className="overlay">
                  <div className="text-center">
                    Our team is available around the clock for urgent repair
                    requests.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="card ten_card_sections" data-aos="fade-down">
                <div className="row text-center">
                  <div className="col-lg-12 col-md-12 col-sm-6 p-1">
                    <h1>
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </h1>
                    <h5 className="Effective_cost text-warning">
                      Comprehensive Inspections
                    </h5>
                  </div>
                </div>
                <div className="overlay">
                  <div className="text-center">
                    <p>
                      Detailed diagnostics help identify potential issues before
                      they become major problems.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="card ten_card_sections" data-aos="fade-up">
                <div className="row text-center">
                  <div className="col-lg-12 col-md-12 col-sm-6 p-1">
                    <h1>
                      <i class="fa-solid fa-comments-dollar"></i>{" "}
                    </h1>
                    <h5 className="Effective_cost text-warning">
                      Cost-Effective Solutions
                    </h5>
                  </div>
                </div>
                <div className="overlay">
                  <div className="text-center">
                    We aim to provide repairs that enhance efficiency while
                    minimizing costs.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Quality You Can Trust
end */}

      {/* Trusted by Leading Industries
start */}

      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
            <img
              className="trusted_leading_image2"
              src={require("../assets/img/Service/Hardware Services/Trusted by.jpg")}
            ></img>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 p-3" data-aos="fade-left">
            <h2 className="text-warning">Trusted by Leading Industries</h2>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-6">
                <p className="pasagejustify">
                  Scimitar Automation has built a strong reputation by working
                  with some of the most respected companies in various sectors.
                  Our dedication to quality and customer service has earned us
                  the trust of our clients. We are proud to have collaborated
                  with
                </p>
              </div>
            </div>
            <div className="">
              <p className="">
                <i class="fa-regular fa-hand-point-right text-warning me-2"></i>{" "}
                Company A
              </p>
              <p className="">
                <i class="fa-regular fa-hand-point-right text-warning me-2"></i>{" "}
                Company B
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-6">
              <p className="pasagejustify">
                Join the ranks of satisfied customers who have experienced our
                exceptional repair services. Let us be your partner in
                maintaining the reliability of your industrial equipment!
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Trusted by Leading Industries
end */}

      {/* why choose us start */}
      <div className="container text-center marginTB">
        <h1 className="text-warning ">Why Choose Us?</h1>

        <div className="row text-center ">
          <div className="col-lg-12 col-md-12 col-sm-6">
            <p className="text-dark text-center">
              Our team at Scimitar Automation comprises highly trained
              technicians with expertise in a variety of industrial equipment,
              including forklifts, conveyors, and pumps. We recognize that
              downtime can be costly, which is why we prioritize fast turnaround
              times and efficient repair processes to minimize disruptions. We
              use only high-quality OEM parts to ensure durability and optimal
              performance, and our repair services are complemented by
              preventive maintenance programs designed to help you avoid future
              issues and extend the lifespan of your machinery.
            </p>
          </div>
        </div>
      </div>

      {/* why choose us end */}

      {/* Our Services
start */}

      <div className="container">
        <div className="row Why_choose_us_equipment">
          <div className="col-lg-3 col-md-3 col-sm-12 ">
            <div
              className="card Why_choose_us_equipment_card p-4"
              data-aos="fade-down"
            >
              <div className="row">
                <h3 className="text-warning">On-Site Repairs</h3>
              </div>
              <hr></hr>
              <div className="row">
                <p className="pasagejustify">
                  Our technicians come to your location to diagnose and repair
                  equipment, saving you time and transportation costs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div
              className="card Why_choose_us_equipment_card p-4 bg-secondary text-white"
              data-aos="fade-up"
            >
              <div className="row">
                <h3 className="text-warning">Emergency Services</h3>
              </div>
              <hr></hr>
              <div className="row">
                <p className="pasagejustify">
                  We offer 24/7 emergency repair services to address unexpected
                  equipment failures and get you back on track as soon as
                  possible
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div
              className="card Why_choose_us_equipment_card p-3"
              data-aos="fade-down"
            >
              <div className="row">
                <h3 className="text-warning">Inspection & Diagnostics</h3>
              </div>
              <hr></hr>
              <div className="row">
                <p className="pasagejustify">
                  Regular inspections can help identify potential problems
                  before they escalate. Our diagnostics services provide you
                  with detailed reports and recommendations.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div
              className="card Why_choose_us_equipment_card p-4 bg-secondary text-white"
              data-aos="fade-up"
            >
              <div className="row">
                <h3 className="text-warning">Custom Solutions</h3>
              </div>
              <hr></hr>
              <div className="row">
                <p className="pasagejustify">
                  We understand that every business is unique. We offer tailored
                  repair solutions that meet your specific needs operational
                  requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our Services
end */}
      {/* Industries We Serve
start */}
      <div className="container text-center ">
        <h1 className="text-warning ">Industries We Serve</h1>
      </div>

      <div className="container marginTB">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
            <img
              className="Industries_We_Serve_Image"
              src={require("../assets/img/Service/Hardware Services/We proudly serve.jpg")}
              // style={{ width: "500px", borderRadius: "20px" }}
            ></img>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
            <h3 className="">
              We proudly serve a variety of industries, including:
            </h3>
            <div className="container ">
              <p className="">
                <i class="fa-regular fa-hand-point-right mx-3 text-warning"></i>
                Manufacturing
              </p>
              <p className="">
                <i class="fa-regular fa-hand-point-right mx-3 text-warning"></i>
                Construction
              </p>
              <p className="">
                <i class="fa-regular fa-hand-point-right mx-3 text-warning"></i>
                Warehousing
              </p>
              <p className="">
                <i class="fa-regular fa-hand-point-right mx-3 text-warning"></i>
                Agriculture
              </p>
              <p className="">
                <i class="fa-regular fa-hand-point-right mx-3 text-warning"></i>
                Food and Beverage
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Industries We Serve
end */}

      {/* Request a Repair Quote Today!
start */}

      {/* <div className="container-fluid p-5 bg-secondary Request_a_Repair_Quote_Today ">
        <div className="row">
          <div className="col-lg-7">
            <h2 className="">
              Contact us to get a personalized quote for your{" "}
            </h2>
            <h2 className="">
              industrial equipment repair needs. We’re here to help!
            </h2>

            <div className="row marginTB ">
              <div className="col-lg-5 ">
                <h1 className="text-white ">123-456-789</h1>
              </div>
              <div className="col-lg-2 left_right_line"></div>
              <div className="col-lg-5 mx-4 ">
                <h1 className="text-white ">123-456-789</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <img
              className=""
              src={require("../assets/img/Service/10008.jpg")}
              style={{ width: "350px", marginLeft: "50px" }}
            ></img>
          </div>
        </div>
      </div> */}

      {/* Request a Repair Quote Today!
end */}

      {/* Get In Touch Start */}

      {/* <div className="container text-center marginTB">
        <h1 className="text-warning ">Get In Touch</h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-6">
            <h5 className="text-dark text-center">
              Don’t let equipment breakdowns slow you down. Contact Scimitar
              Hindustan today to schedule a consultation or request a quote. Our
              team is ready to assist you with all your industrial equipment
              repair needs!
            </h5>
          </div>
        </div>
        <div className="row text-center marginTB">
          <div className="col-lg-12 col-md-12 col-sm-6">
            <button className="btn btn-success">
              Get In Touch <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div> */}

      {/* Get In Touch End */}
    </>
  );
}

export default EquipmentRepair;
