import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./component/Header-Footer/Navbar";
import Footer from "./component/Header-Footer/Footer";
import Home from "./component/Home";
import AboutUs from "./component/AboutUs";
import Contact from "./component/Contact";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Career from "./component/Career";
import AutomationControllers from "./component/AutomationControllers";
import IndustrailAutomation from "./component/IndustrialAutomation";
import Industrial_Robotics from "./component/Industrial_Robotics";
import Control_System_Design from "./component/Control_System_Design";
import Maintenance_Support from "./component/Maintenance_Support";
import Energy_Efficiency_Solutions from "./component/Energy_Efficiency_Solutions";
import AutomotiveAndTyre from "./component/AutomotiveAndTyre";
import Cement from "./component/Cement";
import Mitsubishi from "./component/Mitsubishi";
import Siemens from "./component/Siemens";
import EquipmentRepair from "./component/EquipmentRepair";
import MachineRetrofit from "./component/MachineRetrofit";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/mitsubishi" element={<Mitsubishi />} />
          <Route path="/siemens" element={<Siemens />} />
          <Route path="/equipment-repair" element={<EquipmentRepair />} />

          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/automotive-tyre" element={<AutomotiveAndTyre />} />
          <Route path="/cement" element={<Cement />} />
          <Route path="/machine-retrofit" element={<MachineRetrofit />} />

          <Route
            path="/automation-controllers"
            element={<AutomationControllers />}
          />
          <Route
            path="/industrail-automation"
            element={<IndustrailAutomation />}
          />
          <Route
            path="/industrial-robotics"
            element={<Industrial_Robotics />}
          />
          <Route
            path="/control-system-design"
            element={<Control_System_Design />}
          />
          <Route
            path="/maintenance-support"
            element={<Maintenance_Support />}
          />
          <Route
            path="/energy-efficiency-solutions"
            element={<Energy_Efficiency_Solutions />}
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
