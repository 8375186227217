import { Helmet } from "react-helmet";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "../assets/css/Mitsubishi.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import React, { useState, useEffect } from "react";

function Mitsubishi() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []); // Added data as a dependency// Added data as a dependency
  return (
    <>
      {/* <Helmet>
        <title>{meta ? meta.title : "About"}</title>
      </Helmet> */}
      {/* <!-- Page Header Start --> */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">
            Mitsubishi
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Mitsubishi
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header Start --> */}
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 " data-aos="fade-right">
            <p>Scimitar Automation</p>
            <h2 className="text-warning"> Mitsubishi Solutions</h2>
            <p className="align-RALLIART">
              At Scimitar Automation, we are proud to offer a comprehensive
              range of Mitsubishi products, renowned for their quality,
              reliability, and advanced technology. Mitsubishi Electric is a
              leader in the field of industrial automation, providing solutions
              that enhance efficiency and drive innovation across various
              industries{" "}
            </p>
            <button className=" RALLIART-EDITIONS btn  bg-warning">
              EXPLORE RALLIART EDITIONS
            </button>
          </div>
          <div className="col-lg-6 col-md-6 " data-aos="fade-left">
            <img
              className="w-100"
              src={require("../assets/img/products/Mitsubishi/intro.jpg")}
            />
          </div>
        </div>
      </div>

      <div className="bg-dark text-white">
        <div className="row">
          <div
            className="col-lg-6 text-center Mitsubishi-PLC"
            data-aos="fade-left"
          >
            <p> Mitsubishi PLC </p>
            <h2 className="text-warning">Programmable Logic Controllers</h2>
            <p className="align-DEALERS ">
              Mitsubishi PLCs are engineered to deliver high-speed, precise
              control for diverse automation applications. Their robust
              architecture allows for seamless integration with existing
              systems, making them suitable for everything from simple tasks to
              complex manufacturing processes. With features like modularity and
              scalability, Mitsubishi PLCs adapt to your evolving needs,
              ensuring optimal performance and reliability.{" "}
            </p>
            {/* <button className="p-2 btn w-50 bg-warning">FIND Mitsubishi DEALS</button> */}
          </div>
          <div className="col-lg-6 " data-aos="zoom-in">
            <img
              className="Mitsubishiplc-img"
              style={{ height: "500px", objectFit: "cover" }}
              src={require("../assets/img/products/Mitsubishi/plc.jpg")}
            />
          </div>
        </div>
      </div>

      <div className="text-center pt-3 pb-3 marginTB">
        <h2 className="text-warning">Mitsubishi HMI</h2>
        <h4> (Human-Machine Interface)</h4>
        <p className="align-DEALERS text-center">
          Mitsubishi HMIs provide intuitive and visually engaging interfaces
          that empower operators to monitor and control industrial processes
          with ease. With high-resolution touch screens and customizable
          graphics, these HMIs enhance user interaction, enabling quick
          decision-making and efficient operations. Real-time data visualization
          helps identify issues promptly, improving overall productivity.
        </p>

        <h2 className="text-warning">Mitsubishi SCADA </h2>
        <h4> (Supervisory Control and Data Acquisition)</h4>
        <p className="align-DEALERS text-center">
          Mitsubishi SCADA systems provide comprehensive monitoring and control
          capabilities for industrial processes By integrating SCADA with PLCs
          and HMIs, you gain complete visibility over your processes,
          facilitating informed decision-making and improved responsiveness.{" "}
        </p>
        <div className="row align-DEALERS mt-5">
          <div className="col-lg-3 col-md-3 text-center">
            <i class="fa-regular fa-building fs-1 text-warning"></i>
            <p>Real-time Data </p>
          </div>
          <div className="col-lg-3 col-md-3 text-center">
            <i class="fa-solid fa-list-check fs-1 text-warning"></i>
            <p> Analysis</p>
          </div>
          <div className="col-lg-3  col-md-3 text-center">
            <i class="fa-solid fa-power-off fs-1 text-warning"></i>
            <p>Reporting </p>
          </div>
          <div className="col-lg-3 col-md-3 text-center">
            <i class="fa-regular fa-circle-check fs-1 text-warning"></i>
            <p>Effectively</p>
          </div>
        </div>
      </div>

      <div className="bgimageMitsubishi marginTB d-flex  align-items-center justify-content-center">
        <div className="card Transportation-card ">
          <h2 className="text-warning">
            Mitsubishi VFD (Variable Frequency Drives)
          </h2>
          <p className="text-white">
            Designed to optimize motor control, Mitsubishi VFDs offer precise
            speed and torque regulation, resulting in significant energy savings
            and enhanced performance. These drives are ideal for a wide array of
            applications, from HVAC systems to conveyor belts. By reducing wear
            and tear on equipment, Mitsubishi VFDs help extend the lifespan of
            your machinery, making them a smart investment for your operations.{" "}
          </p>
        </div>
      </div>

      <div className="container marginTB">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <img
              className="whychoose-mitsubishi"
              data-aos="zoom-in"
              src={require("../assets/img/products/Mitsubishi/contororas.jpg")}
            />
          </div>
          <div className="col-lg-6 col-md-12" data-aos="fade-left">
            <div className="Mitsubishi-pasges">
              <h4 className="text-warning col-lg-12 col-md-12">
                Why Choose Mitsubishi with Scimitar Automation?
              </h4>
              <p className="align-DEALERS ">
                <i class="fa-regular fa-hand-point-right fs-5 mt-1 mb-1"></i>{" "}
                &nbsp;&nbsp;By choosing Mitsubishi products through Scimitar
                Automation, you gain access to innovative solutions that enhance
                operational efficiency and drive success. Our team of experts is
                dedicated to helping you implement the right Mitsubishi
                technologies tailored to your specific needs, ensuring you stay
                ahead in a competitive landscape.
              </p>
              <h4 className="text-warning"> Get in Touch</h4>

              <div>
                <p className="align-DEALERS ">
                  <i class="fa-regular fa-hand-point-right fs-5 mt-1 mb-1"></i>{" "}
                  &nbsp;&nbsp; Ready to elevate your industrial processes with
                  Mitsubishi solutions? Contact Scimitar Automation today for
                  more information and to discuss how we can support your
                  automation needs.
                </p>

                <button
                  className="btn btn-success"
                  data-aos="zoom-in"
                  style={{ marginLeft: "50px" }}
                >
                  Get In Touch <i class="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mitsubishi;
