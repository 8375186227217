import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/Footer.css";
import { GoChevronRight } from "react-icons/go";
import {
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaTwitter,
  FaFacebookF,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";

function Footer() {
  return (
    <>
      {/* Footer Start */}

      {/* <footer className="container-fluid footer_container_1 mt-5 py-1">
        <div className="container py-5">
          <div className="row g-4">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <img
                className=""
                src={require("../../assets/img/scimitar_logo_slogan-bg.png")}
                style={{ width: "200px", height: "85px" }}
                alt="Scimitar Logo"
              />
              <div className="d-flex pt-4">
                <a
                  className="btn btn-warning rounded-circle me-2"
                  href="#"
                  aria-label="Twitter"
                >
                  <FaTwitter />
                </a>
                <a
                  className="btn btn-warning rounded-circle me-2"
                  href="#"
                  aria-label="Facebook"
                >
                  <FaFacebookF />
                </a>
                <a
                  className="btn btn-warning rounded-circle me-2"
                  href="#"
                  aria-label="YouTube"
                >
                  <FaYoutube />
                </a>
                <a
                  className="btn btn-warning rounded-circle me-2"
                  href="#"
                  aria-label="LinkedIn"
                >
                  <FaLinkedinIn />
                </a>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-12">
              <h5>Quick Links</h5>
              <div className="footer_socialIcons">
                <a className="btn footer_menu_color" href="/">
                  <GoChevronRight className="mx-2" />
                  Home
                </a>
                <a className="btn footer_menu_color" href="/about">
                  <GoChevronRight className="mx-2" />
                  About us
                </a>
                <a className="btn footer_menu_color" href="#">
                  <GoChevronRight className="mx-2" />
                  Products
                </a>
                <a className="btn footer_menu_color" href="#">
                  <GoChevronRight className="mx-2" />
                  Services
                </a>

                <a className="btn footer_menu_color" href="/contact">
                  <GoChevronRight className="mx-2" />
                  Contact
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <h5>Our Services</h5>
              <div className="footer_socialIcons">
                <a
                  className="btn footer_menu_color"
                  href="/industrail-automation"
                >
                  <GoChevronRight className="mx-2" />
                  Industrial Automation
                </a>
                <a className="btn footer_menu_color" href="#">
                  <GoChevronRight className="mx-2" />
                  Control System Design
                </a>
                <a className="btn footer_menu_color" href="#">
                  <GoChevronRight className="mx-2" />
                  Maintenance & Support
                </a>
                <a className="btn footer_menu_color" href="#">
                  <GoChevronRight className="mx-2" />
                  Consulting Services
                </a>
                <a className="btn footer_menu_color" href="#">
                  <GoChevronRight className="mx-2" />
                  Energy Efficiency Solutions
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 our_Office_container">
              <h5 className="mb-4">Our Office</h5>
              <div className="mb-2 footer_menu_color">
                <FaMapMarkerAlt className="me-3" />
                G-1, 230, Sri P.R, Arcade, Itteri Road, Ram Nagar, Meyyanur,
                Salem -636004.
              </div>
              <div className="mb-2 footer_menu_color">
                <FaPhoneAlt className="me-3" />
                +91-812 231 7080
              </div>
              <div className="mb-2 footer_menu_color">
                <FaEnvelope className="me-3" />
                info@scimitarautomation.com
              </div>
            </div>
          </div>
        </div>
      </footer> */}

      <footer className="">
        <div className="container-fluid footer_backGroundImage ">
          <div className="row p-4">
            <div className="col-lg-3 col-md-6 col-sm-12 Logo_Footer_FirstSection">
              <img
                className="mb-5"
                src={require("../../assets/img/scimitar logo web grey.png")}
                style={{ width: "200px", height: "85px" }}
                alt="Scimitar Logo"
              />

              <div className="footerSocialIcons">
                <a
                  className="btn btn-link text-light"
                  href="#"
                  aria-label="Facebook"
                >
                  <FaFacebookF />
                </a>
                <a
                  className="btn btn-link text-light"
                  href="#"
                  aria-label="Twitter"
                >
                  <FaTwitter />
                </a>
                <a
                  className="btn btn-link text-light"
                  href="#"
                  aria-label="LinkedIn"
                >
                  <FaLinkedinIn />
                </a>
                <a
                  className="btn btn-link text-light"
                  href="#"
                  aria-label="Instagram"
                >
                  <FaInstagram />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 Logo_Footer_SecondSection">
              <h5 className="text-warning">Products</h5>
              <div className="footer_socialIcons">
                <div className="row">
                  <div className="col-md-5">
                    <a
                      className="btn footer_menu_color"
                      href="/industrail-automation"
                    >
                      <GoChevronRight className="mx-1" />
                      Mitsubishi
                    </a>
                    <a className="btn footer_menu_color" href="#">
                      <GoChevronRight className="mx-1" />
                      Siemens
                    </a>
                    <a className="btn footer_menu_color" href="#">
                      <GoChevronRight className="mx-1" />
                      Delta
                    </a>
                    <a className="btn footer_menu_color" href="#">
                      <GoChevronRight className="mx-1" />
                      Omron
                    </a>
                    <a className="btn footer_menu_color" href="#">
                      <GoChevronRight className="mx-1" />
                      Schneider
                    </a>
                  </div>
                  <div className="col-md-5">
                    <a className="btn footer_menu_color" href="#">
                      <GoChevronRight className="mx-1" />
                      Xinje
                    </a>
                    <a className="btn footer_menu_color" href="#">
                      <GoChevronRight className="mx-1" />
                      Inovance
                    </a>
                    <a className="btn footer_menu_color" href="#">
                      <GoChevronRight className="mx-1" />
                      Rockwell
                    </a>
                    <a className="btn footer_menu_color" href="#">
                      <GoChevronRight className="mx-1" />
                      ABB
                    </a>
                    <a className="btn footer_menu_color" href="#">
                      <GoChevronRight className="mx-1" />
                      Others
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 Logo_Footer_ThirdSection">
              <h5 className="text-warning">Services</h5>
              <div className="footer_socialIcons">
                <a className="btn footer_menu_color" href="/equipment-repair">
                  <GoChevronRight className="mx-2" />
                  Hardware Services
                </a>
                <a className="btn footer_menu_color" href="#">
                  <GoChevronRight className="mx-2" />
                  Machine Retrofit
                </a>
                <a className="btn footer_menu_color" href="#">
                  <GoChevronRight className="mx-2" />
                  Panel Fabrication
                </a>
                <a className="btn footer_menu_color" href="#">
                  <GoChevronRight className="mx-2" />
                  Onsite & Field Services
                </a>
                <a className="btn footer_menu_color" href="#">
                  <GoChevronRight className="mx-2" />
                  Training Services
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 Logo_Footer_FourthSection">
              <h5 className="text-warning">Address</h5>

              <div className="mb-2 footer_address_ mt-3">
                <FaMapMarkerAlt className="me-3" />
                29/30, Pavadai St, Kaikolar Thottam, Chidambaram Colony , Erode.
              </div>
              <div className="mb-2 footer_menu_color">
                <FaPhoneAlt className="me-3" />
                +91-812 231 7080
              </div>
              <div className="mb-2 footer_menu_color">
                <FaEnvelope className="me-3" />
                info@scimitarautomation.com
              </div>
            </div>
          </div>
          <hr className="text-warning "></hr>

          <div className="text-center">
            <div className="row mt-3">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <p className=" footer_address_ copyRights_Footer">
                  © Copyright 2024. All Rights Reserved by{" "}
                  <span className="text-warning">Scimitar Hindustan</span>
                </p>
              </div>
              {/* <div className="col-lg-2 col-md-2 col-sm-12 left_right_line"></div> */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h6 className="footer_address_  copyRights_Footer">
                  Designed By Aalan Tech Soft
                </h6>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
