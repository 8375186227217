import React, { useRef, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
import "bootstrap/dist/js/bootstrap.bundle.min"; // Ensure Bootstrap JavaScript is imported
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaPhoneAlt,
} from "react-icons/fa";
// import { GoChevronDown } from "react-icons/go";
import "../../assets/css/Navbar.css";
import { useEffect } from "react";

function Navbar() {
  // Reference for the navbar collapse
  const [searchQuery, setSearchQuery] = useState(""); // To hold the search query

  const navbarCollapseRef = useRef(null);
  const location = useLocation(); // Get the current location

  const navigate = useNavigate(); // Get the navigate function

  // Function to close navbar on link click
  const [toggelShow, setToggelShow] = useState(false);

  function toggleopenclose() {
    if (navbarCollapseRef.current) {
      // Add a check to make sure the element is not null
      if (toggelShow) {
        console.log("Closing the navbar", location.pathname);

        // Close the navbar by removing the "show" class
        navbarCollapseRef.current.classList.remove("show");
      } else {
        console.log("Opening the navbar");

        // Open the navbar by adding the "show" class
        navbarCollapseRef.current.classList.add("show");
      }

      // Toggle the state
      setToggelShow(!toggelShow);
    } else {
      console.error("Navbar collapse element is not found.");
    }
  }

  const closeNavbar = (e) => {
    if (navbarCollapseRef.current.classList.contains("show")) {
      navbarCollapseRef.current.classList.remove("show");
    }

    console.log("Navbar closed");
    setLoading(true);

    // Simulate a delay for loading
    setTimeout(() => {
      setLoading(false);
      if (searchQuery) {
        console.log("Searching for:", searchQuery);
        const found = window.find(searchQuery);
        if (!found) {
          console.log("Search term not found.");
        }
      } else {
        console.log("Search query is empty.");
      }
    }, 1000); // Adjust delay as needed
  };

  const [searchIcon, setSearchIcon] = useState(false);
  function handeleSearch() {
    setSearchIcon(!searchIcon);
    console.log(searchIcon);
  }
  // Handler to capture input changes
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // function eee(){
  //   console.log(searchQuery,"searchQuery,ooo")
  //   window.find(searchQuery)
  //   console.log(window.find(searchQuery))

  // }

  // // Trigger a browser search when Enter is pressed
  // const handleKeyDown = async (e) => {
  //   console.log(e.key,"iss keyyy is")
  //   if (e.key === "Enter") {
  //       // This will search the text within the current page in the browser

  //       console.log(window.find(searchQuery),"find ans");
  //       if(window.find(searchQuery)===false){
  //         navigate("/career")

  //         await eee()
  //         console.log("ppp")

  //         // alert(`Not Found Current Page : ${searchQuery}`)
  //       }

  //   }
  // };

  const [triggerSearch, setTriggerSearch] = useState(false);
  // Trigger the search after navigation

  useEffect(() => {
    if (triggerSearch) {
      eee(); // Perform the search on the new page
      setTriggerSearch(false); // Reset the trigger after search
    }
  }, [triggerSearch]);

  // Function to handle key down event
  // const handleKeyDown = async (e) => {
  //   if (e.key === 'Enter') {
  //     console.log(`Searching for: ${searchQuery}`);
  //     if (window.find(searchQuery) === false) {
  //       console.log('Not found on the current page. Navigating...');
  //       navigate('/career');
  //       setTriggerSearch(true); // Set trigger to perform search after navigation
  //     } else {
  //       console.log('Found on the current page.');
  //     }
  //   }
  // };

  const [searchResults, setSearchResults] = useState({});

  // const searchAcrossRoutes = async () => {
  //   // Simulate searching across routes (replace with actual search logic)
  //   const routes = [
  //     "/about",
  //     "/mitsubishi",
  //     "/siemens",
  //     "/equipment-repair",
  //     "/contact",
  //     "/career",
  //     "/automotive-tyre",
  //     "/cement",
  //     "/machine-retrofit",
  //   ]; // List all routes to search
  //   const results = {};

  //   for (const route of routes) {
  //     // Navigate to each route and check if the search query is present
  //     navigate(route);
  //     // Wait for the page to load and perform the search
  //     await new Promise((resolve) => setTimeout(resolve, 10)); // Adjust timeout as needed

  //     const found = window.find(searchQuery);
  //     setTriggerSearch(true);
  //     if (found) {
  //       results[route] = true; // Route contains the search query
  //     } else {
  //       results[route] = false; // Route does not contain the search query
  //     }
  //   }

  //   setSearchResults(results);
  // };

  const searchAcrossRoutes = async () => {
    // Simulate searching across routes (replace with actual search logic)
    const routes = [
      "/about",
      "/mitsubishi",
      "/siemens",
      "/equipment-repair",
      "/contact",
      "/career",
      "/automotive-tyre",
      "/cement",
      "/machine-retrofit",
      "/",
    ]; // List all routes to search
    const results = {};

    for (const route of routes) {
      // Navigate to each route
      navigate(route);
      setLoading(true);
      console.log(route, "ppp route");

      // Wait for the page to load (use a more reliable way than setTimeout if available)
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust timeout as needed

      // Perform the search
      const found = window.find(searchQuery);

      // If the search query is found, set the trigger and stop further navigation
      if (found) {
        setTriggerSearch(true);
        results[route] = true; // Mark the route as containing the search query
        setLoading(false);

        break; // Stop iterating as we found the query
      } else {
        results[route] = false; // Route does not contain the search query
        setLoading(false);
      }
    }

    setSearchResults(results);
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      console.log(`Searching for: ${searchQuery}`);
      if (window.find(searchQuery) === false) {
        console.log(
          "Not found on the current page. Searching across routes..."
        );
        await searchAcrossRoutes();
        // Decide which route to navigate based on search results
        for (const [route, found] of Object.entries(searchResults)) {
          if (found) {
            navigate(route);
            setTriggerSearch(true);

            return; // Navigate to the first route that contains the search query
          }
        }
        console.log("Search query not found in any route.");
      } else {
        console.log("Found on the current page.");
      }
    }
  };

  // Function to perform search on the new page
  const eee = () => {
    console.log(`Performing search on the new page for: ${searchQuery}`);
    const found = window.find(searchQuery);
    console.log(found ? "Found on new page!" : "Not found on new page.");
  };

  // const [searchResults, setSearchResults] = useState({});
  // const [currentRoute, setCurrentRoute] = useState('/');

  // // Perform search when route changes or triggerSearch is set
  // useEffect(() => {
  //   if (triggerSearch) {
  //     eee();
  //     setTriggerSearch(false); // Reset trigger
  //   }
  // }, [triggerSearch, location]);

  // const eee = () => {
  //   console.log(`Performing search on the new page for: ${searchQuery}`);
  //   const found = window.find(searchQuery);
  //   console.log(found ? 'Found on new page!' : 'Not found on new page.');
  // };

  // const searchAcrossRoutes = async () => {
  //   const routes = ['/career', '/contact', '/mitsubishi'];
  //   const results = {};

  //   for (const route of routes) {
  //     navigate(route);
  //     setCurrentRoute(route);

  //     // Wait for the page to load before performing the search
  //     await new Promise(resolve => setTimeout(resolve, 1000)); // Increase timeout as needed

  //     // Trigger the search after navigation
  //     setTriggerSearch(true);

  //     // Wait for the search to be performed
  //     await new Promise(resolve => setTimeout(resolve, 1000)); // Ensure eee() has completed

  //     const found = window.find(searchQuery);
  //     results[route] = found;

  //     if (found) {
  //       results[route] = true; // Route contains the search query
  //     } else {
  //       results[route] = false; // Route does not contain the search query
  //     }
  //   }

  //   setSearchResults(results);
  // };

  // const handleKeyDown = async (e) => {
  //   if (e.key === 'Enter') {
  //     console.log(`Searching for: ${searchQuery}`);
  //     if (window.find(searchQuery) === false) {
  //       console.log('Not found on the current page. Searching across routes...');
  //       await searchAcrossRoutes();
  //       // Decide which route to navigate based on search results
  //       for (const [route, found] of Object.entries(searchResults)) {
  //         if (found) {
  //           navigate(route);
  //           return; // Navigate to the first route that contains the search query
  //         }
  //       }
  //       console.log('Search query not found in any route.');
  //     } else {
  //       console.log('Found on the current page.');
  //     }
  //   }
  // };
  const [loading, setLoading] = useState(false);

  const handleRouteChange = () => {
    console.log("hi");
    setLoading(true);
    // Simulate a delay for loading
    setTimeout(() => {
      setLoading(false);
    }, 8000); // Adjust delay as needed
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    handleClose();
  };

  return (
    <div>
      {loading === true ? (
        <div className="loading-overlay">
          <div className="loading-message">
            <img
              className="loadinggif"
              src={require("../../assets/img/products/BrandLogo/output-onlinegiftools.gif")}
            />{" "}
          </div>
        </div>
      ) : (
        <div className="d-none"></div>
      )}
      {/* Topbar Start */}
      <div className="container-fluid bg-dark px-0">
        <div className="row g-0 d-none d-lg-flex">
          <div className="col-lg-6 ps-5 text-start">
            <div className="h-100 d-inline-flex align-items-center text-white">
              <a
                className="btn btn-link text-light"
                href="#"
                aria-label="Facebook"
              >
                <FaFacebookF />
              </a>
              <a
                className="btn btn-link text-light"
                href="#"
                aria-label="Twitter"
              >
                <FaTwitter />
              </a>
              <a
                className="btn btn-link text-light"
                href="#"
                aria-label="LinkedIn"
              >
                <FaLinkedinIn />
              </a>
              <a
                className="btn btn-link text-light"
                href="#"
                aria-label="Instagram"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
          <div className="col-lg-6 text-end">
            <div className="h-100 topbar-right d-inline-flex align-items-center text-white py-2 px-5">
              <span className="me-2">
                <FaPhoneAlt className="me-2" />
                Call Us:
              </span>
              <span className="">+91-812 231 7080</span>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}

      <nav className="navbar navbar-expand-lg">
        <div className="row toggel_bottom ">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-6 col-md-5 d-flex ">
                <NavLink to="/about" exact className="navbar-brand">
                  <img
                    src={require("../../assets/img/scimitar logo web black.png")}
                    alt="Logo"
                    className="text-white toggel_img mx-5"
                  />
                </NavLink>
                <button
                  onClick={toggleopenclose}
                  className="navbar-toggler toggel_button "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarContent"
                  aria-controls="navbarContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  // style={{ width: "40px", height: "30px", marginLeft: "40px", marginTop: "20px" }}
                >
                  <span className="navbar-toggler-icon toggel_icon"></span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Collapsible content */}
        <div
          className="collapse navbar-collapse"
          id="navbarCollapse"
          ref={navbarCollapseRef}
        >
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            {/* <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Products
              </a>

              <div className="dropdown-menu dropDown_Menu bg-light m-0 p-3 ">
                <div className="row">
                  <div className="col-5 ">
                   
                    <NavLink
                      to="/mitsubishi"
                      className="dropdown-item "
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Mitsubishi
                    </NavLink>

                    <NavLink
                      to="/siemens"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Siemens
                    </NavLink>
                    <NavLink
                      to="/chemical"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Delta
                    </NavLink>
                    <NavLink
                      to="/fibre-textiles"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Omrom
                    </NavLink>
                    <NavLink
                      to="/food-beverage"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Schnider
                    </NavLink>
                  </div>
                  <div className="col-6 ">
                    <NavLink
                      to="/infrastructure"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Xinje
                    </NavLink>
                    <NavLink
                      to="/marine"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Inoyane
                    </NavLink>
                    <NavLink
                      to="/metals"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Rock Well
                    </NavLink>
                    <NavLink
                      to="/mining"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      ABB
                    </NavLink>
                    <NavLink
                      to="/oil-gas"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Others
                    </NavLink>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Products
              </a>

              <div className="dropdown-menu dropDown_Menu bg-light m-0 p-3">
                <div className="row">
                  <div className="col-lg-5">
                    <NavLink
                      to="/mitsubishi"
                      className="dropdown-item"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      <img
                        src={require("../../assets/img/products/BrandLogo/Mitsubishi_logo.png")}
                        // alt="Mitsubishi Logo"
                        className="me-2"
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "cover",
                        }}
                      />
                      Mitsubishi
                    </NavLink>

                    <NavLink
                      to="/siemens"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      <img
                        src={require("../../assets/img/products/BrandLogo/Siemens_logo.jpg")}
                        // alt="Siemens Logo"
                        className="me-2"
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "cover",
                        }}
                      />
                      Siemens
                    </NavLink>

                    <NavLink
                      to="/chemical"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      <img
                        src={require("../../assets/img/products/BrandLogo/delta-logo.png")}
                        // alt="Delta Logo"
                        className="me-2"
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "cover",
                        }}
                      />
                      Delta
                    </NavLink>

                    <NavLink
                      to="/fibre-textiles"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      <img
                        src={require("../../assets/img/products/BrandLogo/Omron_logo.jpg")}
                        // alt="Omron Logo"
                        className="me-2"
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "cover",
                        }}
                      />
                      Omron
                    </NavLink>

                    <NavLink
                      to="/food-beverage"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      <img
                        src={require("../../assets/img/products/BrandLogo/Scheider_logo.png")}
                        // alt="Schneider Logo"
                        className="me-2"
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "cover",
                        }}
                      />
                      Schneider
                    </NavLink>
                  </div>
                  <div className="col-lg-7">
                    <NavLink
                      to="/infrastructure"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      <img
                        src={require("../../assets/img/products/BrandLogo/Xinje_log.png")}
                        // alt="Xinje Logo"
                        className="me-2"
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "cover",
                        }}
                      />
                      Xinje
                    </NavLink>
                    <NavLink
                      to="/marine"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      <img
                        src={require("../../assets/img/products/BrandLogo/inovance.jpg")}
                        // alt="Inovance Logo"
                        className="me-2"
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "cover",
                        }}
                      />
                      Inovance
                    </NavLink>
                    <NavLink
                      to="/metals"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      <img
                        src={require("../../assets/img/products/BrandLogo/Ra_logo.jpg")}
                        // alt="Rockwell Logo"
                        className="me-2"
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "cover",
                        }}
                      />
                      RockWell Automations
                    </NavLink>
                    <NavLink
                      to="/mining"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      <img
                        src={require("../../assets/img/products/BrandLogo/Abb_log.png")}
                        // alt="ABB Logo"
                        className="me-2"
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "cover",
                        }}
                      />
                      ABB
                    </NavLink>
                    <NavLink
                      to="/oil-gas"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      <img
                        src={require("../../assets/img/products/BrandLogo/Others_logo1.png")}
                        // alt="Others Logo"
                        className="me-2"
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "cover",
                        }}
                      />
                      Others
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            {/* Services Dropdown */}
            <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Services
              </a>
              <div className="dropdown-menu bg-light m-0">
                <NavLink
                  to="/equipment-repair"
                  className="dropdown-item "
                  activeClassName="active"
                  onClick={closeNavbar}
                >
                  Hardware services{" "}
                </NavLink>
                <NavLink
                  to="/machine-retrofit"
                  className="dropdown-item mt-2"
                  activeClassName="active"
                  onClick={closeNavbar}
                >
                  Machine Retrofit
                </NavLink>
                <NavLink
                  to="/maintenance-support"
                  className="dropdown-item mt-2"
                  activeClassName="active"
                  onClick={closeNavbar}
                >
                  Panel Fabrication
                </NavLink>
                <NavLink
                  to="/energy-efficiency-solutions"
                  className="dropdown-item mt-2"
                  activeClassName="active"
                  onClick={closeNavbar}
                >
                  Onsite & Field Services
                </NavLink>
                <NavLink
                  to="/training-services"
                  className="dropdown-item mt-2"
                  activeClassName="active"
                  onClick={closeNavbar}
                >
                  Training Services
                </NavLink>
              </div>
            </div>

            {/* Industries */}

            <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Industries
              </a>

              <div className="dropdown-menu dropDown_Menu bg-light m-0 p-3 ">
                <div className="row">
                  <div className="col-6 ">
                    {/* <NavLink
                      to="/automation-controllers"
                      className="dropdown-item dropDown_Item"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Automotive & Tyre
                    </NavLink> */}
                    <NavLink
                      to="/automotive-tyre"
                      className="dropdown-item "
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Automotive & Tyre
                    </NavLink>

                    <NavLink
                      to="/cement"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Cement
                    </NavLink>
                    <NavLink
                      to="/chemical"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Chemical
                    </NavLink>
                    <NavLink
                      to="/fibre-textiles"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Fibre & Textiles
                    </NavLink>
                    <NavLink
                      to="/food-beverage"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Food & Beverage
                    </NavLink>
                    <NavLink
                      to="/power-generation"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Power Generation
                    </NavLink>
                    <NavLink
                      to="/print-publishing"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Print & Publishing
                    </NavLink>
                    <NavLink
                      to="/pulp-paper"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Pulp & Paper
                    </NavLink>
                  </div>
                  <div className="col-6 ">
                    <NavLink
                      to="/infrastructure"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Infrastructure
                    </NavLink>
                    <NavLink
                      to="/marine"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Marine
                    </NavLink>
                    <NavLink
                      to="/metals"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Metals
                    </NavLink>
                    <NavLink
                      to="/mining"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Mining
                    </NavLink>
                    <NavLink
                      to="/oil-gas"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Oil & Gas
                    </NavLink>
                    <NavLink
                      to="/waste-management"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Waste Management
                    </NavLink>
                    <NavLink
                      to="/water-management"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      Water Management
                    </NavLink>
                    {/* <NavLink
                      to="/not-found"
                      className="dropdown-item mt-2"
                      activeClassName="active"
                      onClick={closeNavbar}
                    >
                      404 Page
                    </NavLink> */}
                  </div>
                  {/* <div className="col-6"> */}

                  {/* </div> */}
                  {/* <div className="col-6"> */}

                  {/* </div> */}
                </div>
              </div>
            </div>

            {/* Industries end */}

            <NavLink
              to="/career"
              className="nav-item nav-link"
              activeClassName="active"
              onClick={closeNavbar}
            >
              Career
            </NavLink>
            <NavLink
              to="/contact"
              className="nav-item nav-link"
              activeClassName="active"
              onClick={closeNavbar}
            >
              Contact
            </NavLink>
          </div>

          {/* search icon */}
          <div className="mobile-view">
            <input
              type="search"
              className={`${
                searchIcon ? "search-style" : "d-none"
              } form-control rounded`}
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              value={searchQuery}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            />{" "}
            <button
              type="button"
              onClick={handeleSearch}
              className="btn btn-outline-danger border-0 mx-3"
              data-mdb-ripple-init
            >
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
            {/* search icon end*/}
          </div>
          {/* Get A Quote button */}
          {/* <button
            className="btn btn-warning px-3 d-none d-lg-block me-5"
            style={{ width: "147px" }}
            onClick={closeNavbar}
          >
            Get A Quote
          </button> */}
          <button
            className="btn btn-warning px-3 d-none d-lg-block me-5"
            style={{ width: "147px" }}
            onClick={() => {
              handleShow();
              closeNavbar();
            }}
          >
            Get A Quote
          </button>

          {/* <button
            className="btn btn-warning px-3 d-none d-lg-block me-5"
            style={{ width: "147px" }}
            onClick={openPopup, closeNavbar}
          >
            Get A Quote
          </button> */}
        </div>
      </nav>

      {/* Popup Modal Start */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="custom-modal"
      >
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title className="text-white">GET A QUOTE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-3">
            <div className="col-lg-12">
              <p className="text-secondary fs-6 text-center">
                Please fill out this form, and we will notify you. Our team will
                contact you shortly!
              </p>
            </div>
          </div>

          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3 row">
              <div className="col-lg-12">
                <Form.Control type="text" placeholder="Name" required />
              </div>
            </Form.Group>

            <Form.Group className="mb-3 row">
              <div className="col-lg-12">
                <Form.Control type="tel" placeholder="Mobile" required />
              </div>
            </Form.Group>

            <Form.Group className="mb-3 row">
              <div className="col-lg-12">
                <Form.Control type="email" placeholder="Email" required />
              </div>
            </Form.Group>

            <Form.Group className="mb-3 row">
              <div className="col-lg-12">
                <Form.Select required>
                  <option value="">Select Service Type</option>
                  <option value="service1">Hardware Services</option>
                  <option value="service2">Machine Retrofit</option>
                  <option value="service3">Panel Fabrication</option>
                  <option value="service4">Onsite & Field Services</option>
                  <option value="service5">Training Services</option>
                </Form.Select>
              </div>
            </Form.Group>

            <Form.Group className="mb-3 row">
              <div className="col-lg-12">
                <Form.Control
                  as="textarea"
                  placeholder="Message"
                  rows={3}
                  required
                />
              </div>
            </Form.Group>
            <div className="text-center">
              <Button className="btn text-white" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* </div> */}
    </div>
  );
}

export default Navbar;
