import React, { useEffect, useState } from "react";
import "../assets/css/Career.css";
import data from "../../src/component/ApiUrl/meta_api";
import { Helmet } from "react-helmet";
import AOS from "aos";

function Career() {
  const [meta, setMeta] = useState(null);

  const fetchData = () => {
    const CareerMeta = data[0]?.career_page;
    if (CareerMeta) {
      setMeta(CareerMeta);
    }
  };

  useEffect(() => {
    fetchData();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <Helmet>
        <title>{meta ? meta.title : "Career"}</title>
      </Helmet>
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">Career</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              {/* <li className="breadcrumb-item">
                <a href="#">Pages</a>
              </li> */}
              <li className="breadcrumb-item active" aria-current="page">
                Career
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* join our team start */}
      {/* <div className="container ">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
            <div className="container bg-secondary team_section p-5">
              <h1 className="text-warning">Join Our Team At</h1>
              <h1 className="text-warning">Career!</h1>
              <p className="text-white">
                Work at the most dynamic and successful agency
                 in a fast-paced agency often requires quick decision-making and the ability to pivot strategies
              </p>
              <button className="btn btn-warning text-white">
                View Openings
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 team_section mb-5 ">
            <img
              className="join_our_team_image"
              src={require("../assets/img/Career/istockphoto-1300679308-612x612.jpg")}
              style={{ width: "100%", height: "100%" ,objectFit:"cover"}}
            ></img>
          </div>
        </div>
      </div>
     */}

      {/* join our team section 2 start */}
      <div className="bg-white ">
        <div className="container ">
          <div className="row">
            {/* <div className="col-lg-4 col-md-6 col-sm-12">
              <img
                className="Careerly-img"
                src={require("../assets/img/Career/istockphoto-1301397300-612x612.jpg")}
              />
            </div> */}
            <div
              className="col-lg-12 col-md-6 col-sm-12   "
              data-aos="fade-left"
            >
              <div className="At-Careerly">
                <h1 className="text-warning text-center mb-5">
                  Join Our Team At Careerly
                </h1>
                <p className="">
                  At Careerly we believe in working together and working hard.
                  With happy clients, we are looking for dynamic and creative
                  individuals who are willing to dedicate themselves to
                  providing innovative products and services for our clients.
                </p>

                <p className="">
                  Besides getting the opportunity to unlock your true potential,
                  at Careerly you can also network with some of the most
                  talented people in the industry, go on annual picnics outside
                  the country and enjoy many other benefits by working with us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* join our team section 2 end */}

      {/* form section start */}

      <div className="Get-critical p-4">
        <h2 className="text-center text-warning">
          Explore Opportunities with Our Talent Network
        </h2>

        <h5 className="text-center  Fill_Contact mt-3">
          Fill in Your Contact Details
        </h5>
        <div className="row">
          <div className="col-lg-7">
            <div className="container  bg_card_career">
              <div className="row text-white">
                <div className="col-lg-12 mt-4 mb-3">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-control career-name"
                      id="name"
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control career-name"
                      id="email"
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number</label>
                    <input
                      type="text"
                      className="form-control career-name"
                      id="mobileNumber"
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="fileUpload">Upload File</label>
                    <input
                      type="file"
                      className="form-control career-name"
                      id="fileUpload"
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="row">
                    <div className="col-lg-1">
                      <input
                        type="checkbox"
                        id="terms"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="col-lg-11" style={{ marginLeft: "-30px" }}>
                      <p>
                        By Joining our community, we will store your personal
                        information and monitor your engagement for up to 2
                        years after your last activity, ensuring you're
                        considered for job opportunities and contacted by
                        recuriters aligned with your interests.
                      </p>
                    </div>
                  </div>
                  {/* <div className="form-group">
                   
                    
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <button
                    type="submit"
                    className={`btn btn-warning ${isChecked ? "" : "blur"}`}
                    disabled={!isChecked}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5" data-aos="fade-left">
            <img
              className=" critical-skills"
              src={require("../assets/img/Home/10002.jpg")}
            ></img>
          </div>
        </div>
      </div>

      <div>
        <div className="container core-valu-overall ">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row g-3">
                {/* First Row - Two Columns */}
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div
                    className="card core-value p-3 shadow-sm"
                    data-aos="fade-down"
                  >
                    <i className="fa-solid fa-house h2 text-secondary"></i>
                    <h4 className="text-warning mt-2">
                      Large Beautiful Office
                    </h4>
                    <p className="text-muted">
                      Enjoy a comfortable office environment with modern and
                      stylish furnishings.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div
                    className="card core-value p-3 shadow-sm"
                    data-aos="fade-up"
                  >
                    <i className="fa-solid fa-users h2 text-secondary"></i>
                    <h4 className="text-warning mt-2">Great Co-Workers</h4>
                    <p className="text-muted">
                      Work with talented individuals and build strong networks.
                    </p>
                  </div>
                </div>

                {/* Second Row - Two Columns */}
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div
                    className="card core-value p-3 shadow-sm"
                    data-aos="fade-down"
                  >
                    <i className="fa-solid fa-location-dot h2 text-secondary"></i>
                    <h4 className="text-warning mt-2">Easy Location</h4>
                    <p className="text-muted">
                      Commute easily to work with compensation for transport
                      costs.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div
                    className="card core-value p-3 shadow-sm"
                    data-aos="fade-up"
                  >
                    <i className="fa-solid fa-award h2 text-secondary"></i>
                    <h4 className="text-warning mt-2">Performance Award</h4>
                    <p className="text-muted">
                      Get rewarded for excellent performance every 6 months.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Career;
