function Control_System_Design() {
  return (
    <>
      <div
        className="container-fluid IndustrialAutomation_page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">
            Control System Design
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Pages</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Control System Design
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Optimizing Precision and Performance for Automated Systems
Start */}

      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h1 className="text-dark">
              Optimizing Precision and Performance for Automated Systems
            </h1>
            <p className="">
              At Scimitar Hindustan , we specialize in providing advanced
              Control System Design services tailored to optimize industrial
              processes. Our custom-designed control systems ensure that your
              automated machinery and processes run smoothly, efficiently, and
              with precise control. Whether it's for a single machine or an
              entire production line, our control systems offer seamless
              integration, real-time monitoring, and enhanced performance.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/project-2.jpg")}
            ></img>
          </div>
        </div>
      </div>

      {/* Optimizing Precision and Performance for Automated Systems
End */}

      {/* What is Control System Design?
Start */}

      <div className="container-fluid mt-5 mb-5 footer_container_1 p-5">
        <h1 className="text-warning text-center">Control System Design</h1>

        <div className="container text-white">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-6 text-center">
              <p className="">
                Control system design involves creating systems that regulate
                the behavior of devices and machinery within industrial
                operations. These systems automate the control of critical
                factors like temperature, pressure, motion, and flow, ensuring
                that operations are precise, consistent, and efficient. By using
                technologies such as Programmable Logic Controllers (PLCs),
                Supervisory Control and Data Acquisition (SCADA), and
                Distributed Control Systems (DCS), we help industries streamline
                processes and improve productivity.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* What is Control System Design?
End */}

      {/* Our Control System Design Services start */}

      <div className="container mb-5">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-12">
            <h1 className="">
              PLC (Programmable Logic Controller) Programming
            </h1>
            <p className="">
              We design and program PLCs to control and automate machinery and
              industrial processes. Our PLC solutions ensure your operations are
              reliable, efficient, and adaptable to various tasks.
            </p>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center ">
                    <p className="mt-2">Scalable and flexible designs</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">
                      High reliability for critical applications
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">Integration with sensors, actuators</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">Remote monitoring and controls</p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-12 col-md-12 col-sm-6">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">Easy maintenance and future upgrades</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/project-4.jpg")}
            ></img>
          </div>
        </div>
      </div>

      {/* Our Control System Design Services end */}

      {/* ⦁	SCADA Systems (Supervisory Control and Data Acquisition):
start */}

      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/project-4.jpg")}
            ></img>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12">
            <h1 className="">
              SCADA Systems (Supervisory Control and Data Acquisition){" "}
            </h1>
            <p className="">
              Our SCADA systems provide centralized monitoring and control of
              your entire production facility. With real-time data acquisition
              and detailed process visualization, SCADA helps you manage,
              control, and optimize operations from a single interface.
            </p>
            <div className="container">
              <div className=" ">
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mx-2 "
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Real-time data acquisition and visualization
                </h6>
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Remote access and control for efficient management
                </h6>
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Advanced alarm management for faster issue resolution
                </h6>
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Historical data logging and reporting for insights and
                  analytics
                </h6>
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Easy integration with PLCs, sensors, and other devices
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ⦁	SCADA Systems (Supervisory Control and Data Acquisition):
 end */}

      {/* Why Choose [Your Company Name] for Control System Design?
start */}

      <div className="container-fluid footer_container_1 p-5 mb-5">
        <div className="container mt-5 mb-5">
          <h1 className="text-warning text-center">
            Why Choose Scimitar Hindustan for Control System Design?
          </h1>
        </div>

        <div className="row p-5">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="row">
              <div className="col-lg-2 text-end">
                <i class="fa-solid fa-house h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b> Expert Engineers: </b>Our team consists of experienced
                  engineers with deep expertise in control systems, ensuring you
                  receive the best solution for your needs.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="row">
              <div className="col-lg-2 text-end ">
                {/* <i class="fa-solid fa-house h1 text-warning"></i> */}
                {/* <i class="fa-solid fa-chart-simple h1 text-warning"></i> */}
                <i class="fa-solid fa-chart-line h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b> Tailored Solutions: </b>We understand that each business
                  has unique requirements. Our control systems are
                  custom-designed to meet your specific operational needs and
                  business goals.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-5">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="row">
              <div className="col-lg-2 text-end ">
                {/* <i class="fa-solid fa-house h1 text-warning"></i> */}
                <i class="fa-solid fa-shield h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b> Seamless Integration: </b>Whether implementing new systems
                  or upgrading existing ones, we ensure seamless integration
                  with your current equipment and processes.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="row">
              <div className="col-lg-2 text-end">
                {/* <i class="fa-solid fa-house h1 text-warning"></i> */}
                <i class="fa-solid fa-star h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b> Cutting-Edge Technology: </b>We use the latest
                  technologies in PLCs, SCADA, DCS, and HMI design to provide
                  future-proof solutions.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-5">
          <div className="col-lg-3 col-md-3 col-sm-6"></div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <div className="col-lg-2 text-warning  text-end">
                {/* <i class="fa-solid fa-house h1 text-warning"></i> */}
                <i class="fa-solid fa-bolt h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b>End-to-End Support: </b>From design and installation to
                  training and ongoing maintenance, we provide full support
                  throughout the lifecycle of your control system.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6"></div>
        </div>
      </div>

      {/* Why Choose [Your Company Name] for Control System Design?
end */}

      {/* ⦁	DCS (Distributed Control Systems):
start */}

      <div className="container mt-5 mb-5">
        <h1 className=" text-center">DCS (Distributed Control Systems) </h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="card bg-secondary text-white p-5">
              <div className="text-center h3 advanced_section_icon">
                <i class="fa-solid fa-circle-check h1 text-warning"></i>
              </div>
              <p className=" mt-5">
                Decentralized control refers to a control strategy where
                decision-making
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="card p-5">
              <div className="text-center h3 advanced_section_icon">
                <i class="fa-solid fa-circle-check  h1 text-warning"></i>
              </div>
              <p className=" mt-5">
                High reliability refers to the ability of a system or component
                to perform
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="card bg-secondary text-white p-5">
              <div className="text-center h3 advanced_section_icon ">
                <i class="fa-solid fa-circle-check h1 text-warning"></i>
              </div>
              <p className=" mt-5">
                Integration in automation systems involves connecting different
                systems
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="card p-5">
              <div className="text-center h3 advanced_section_icon">
                <i class="fa-solid fa-circle-check h1 text-warning"></i>
              </div>
              <p className=" mt-5">
                Scalable Architecture refers to the design of a system that
                allows for easy expansion
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* ⦁	DCS (Distributed Control Systems):
end */}

      {/* ⦁	Custom HMI (Human-Machine Interface) Design:
Start */}

      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-12">
            <h1 className=""> Custom HMI (Human-Machine Interface) Design </h1>
            <p className="">
              We develop custom HMI solutions that allow operators to interact
              seamlessly with the control system. Our HMIs are designed to
              provide intuitive control and real-time feedback, ensuring easy
              operation and monitoring of processes.
            </p>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">User-friendly, intuitive interfaces</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">Real-time data display for process</p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">Customizable dashboards for specific</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">Remote access for monitoring</p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-12 col-md-12 col-sm-6">
                  <div className="card industrail_cardSection p-1 text-center">
                    <p className="mt-2">
                      Alarm systems for immediate issue detection
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/project-4.jpg")}
            ></img>
          </div>
        </div>
      </div>

      {/* ⦁	Custom HMI (Human-Machine Interface) Design:
End */}

      {/* Benefits of Advanced Control System Design:
start */}

      <div className="container mt-5 mb-5">
        <h1 className="text-warning text-center">
          Benefits of Advanced Control System Design
        </h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card p-4 bg-secondary ">
              <h4 className="text-warning"> Improved Efficiency </h4>
              <p className="">
                By automating and optimizing processes, control systems reduce
                downtime, eliminate inefficiencies, and improve productivity.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card p-4 bg-secondary">
              <h4 className="text-warning"> Enhanced Precision </h4>
              <p className="">
                Advanced control systems provide precise management of
                machinery, ensuring consistent product quality and minimizing
                errors.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card p-4 bg-secondary">
              <h4 className="text-warning">Real-Time Monitoring</h4>
              <p className="">
                With real-time data collection and analysis, control systems
                allow operators to make informed decisions and address potential
                issues before they escalate.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          {/* <div className="col-lg-2 col-md-2 col-sm-2"></div> */}
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card p-4 text-center bg-secondary">
              <h4 className="text-warning"> Reduced Operational Costs</h4>
              <p className="">
                By optimizing processes, control systems reduce energy
                consumption, labor costs, and material waste.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card p-4 text-center bg-secondary">
              <h4 className="text-warning"> Scalability and Flexibility</h4>
              <p className="">
                Our systems are designed to grow with your business, allowing
                you to scale operations as needed and adapt to changing
                production demands.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card p-4 text-center bg-secondary">
              <h4 className="text-warning"> Increased Safety</h4>
              <p className="">
                Automation of hazardous or complex tasks ensures worker safety
                and minimizes the risk of accidents or human error.
              </p>
            </div>
          </div>
          {/* <div className="col-lg-2 col-md-2 col-sm-2"></div> */}
        </div>
      </div>

      {/* Benefits of Advanced Control System Design:
end */}

      <div className="container-fluid mt-5 mb-5 footer_container_1 p-5">
        <h1 className="text-warning text-center">Industries We Serve </h1>

        <div className="container text-white mt-5">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="container mb-5">
                <h5 className="">
                  {" "}
                  <i class="fa-solid fa-circle-check h1 text-warning mx-2"></i>{" "}
                  Manufacturing
                </h5>
              </div>
              <div className="container mb-5">
                <h5 className="">
                  {" "}
                  <i class="fa-solid fa-circle-check h1 text-warning mx-2"></i>{" "}
                  Energy & Utilities
                </h5>
              </div>
              <div className="container mb-5">
                <h5 className="">
                  {" "}
                  <i class="fa-solid fa-circle-check h1 text-warning mx-2"></i>{" "}
                  Pharmaceutical
                </h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="card ">
                <img
                  className=""
                  // src={require("../assets/img/images (12).jpg")}
                ></img>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ">
              <div className="container mb-5 ">
                <h5 className="">
                  {" "}
                  <i class="fa-solid fa-circle-check h1 text-warning mx-2"></i>{" "}
                  Food & Beverage
                </h5>
              </div>
              <div className="container mb-5">
                <h5 className="">
                  {" "}
                  <i class="fa-solid fa-circle-check h1 text-warning mx-2"></i>{" "}
                  Oil & Gas
                </h5>
              </div>
              <div className="container mb-5">
                <h5 className="">
                  {" "}
                  <i class="fa-solid fa-circle-check h1 text-warning mx-2"></i>{" "}
                  Automotive
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Industries We Serve:
start */}

      <></>

      {/* Industries We Serve:
end */}
    </>
  );
}

export default Control_System_Design;
