//import React from "react"; // Import React
import React, { useEffect, useState } from "react";
import "../assets/css/IndustrialAutomation.css";
import data from "../../src/component/ApiUrl/meta_api";
import { Helmet } from "react-helmet";
import AOS from "aos";

function IndustrialAutomation() {
  // const [selectedStep, setSelectedStep] = useState(null);

  // const handleClick = (stepNumber) => {
  //   const step = steps.find((s) => s.number === stepNumber);
  //   setSelectedStep(step);
  // };

  // useEffect(() => {
  //   handleClick(1);
  // }, []);

  // const steps = [
  //   {
  //     number: 1,
  //     heading: "Consultation and Needs Assessment",
  //     subheading:
  //       "We begin by understanding your business, goals, and challenges. Our team will assess your current processes to identify automation opportunities and tailor solutions to meet your needs.",
  //     image: "../assets/img/Home/10001.jpg",
  //   },
  //   {
  //     number: 2,
  //     heading: "Custom Solution Design",
  //     subheading:
  //       "Based on the assessment, we design a customized automation system that integrates the latest technologies, ensuring it fits seamlessly into your existing operations and aligns with your long-term goals.",
  //     image: "../assets/img/Home/10002.jpg",
  //   },
  //   {
  //     number: 3,
  //     heading: "Implementation and Integration",
  //     subheading:
  //       "Our team of experts will install and integrate the automation system with minimal disruption to your operations, ensuring a smooth transition and maximum uptime.",
  //     image: "../assets/img/Home/10003.jpg",
  //   },
  //   {
  //     number: 4,
  //     heading: "Training and Support",
  //     subheading:
  //       "We provide comprehensive training for your team, ensuring they can operate and maintain the system efficiently. We also offer ongoing support to address any technical issues and keep your system running smoothly.",
  //     image: "../assets/img/Home/10001.jpg",
  //   },
  //   {
  //     number: 5,
  //     heading: "Continuous Optimization",
  //     subheading:
  //       "As your business evolves, so should your automation system. We offer continuous monitoring and optimization services to ensure your system adapts to your changing needs, ensuring peak performance at all times.",
  //     image: "../assets/img/Home/10003.jpg",
  //   },
  // ];

  const [meta, setMeta] = useState(null);

  const fetchData = () => {
    const Industrial_AutomationMeta = data[0]?.industrialAutomation_page;
    if (Industrial_AutomationMeta) {
      setMeta(Industrial_AutomationMeta);
    }
  };

  useEffect(() => {
    fetchData();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>{meta ? meta.title : "Industrail_Automation"}</title>
      </Helmet>
      {/* Banner Section */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-3 text-white animated slideInRight">
            Industrial Automation
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb animated slideInRight mb-0">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Services</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Industrial Automation
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Revolutionizing Your Operations with Intelligent Automation start */}

      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
            <h1 className="text-dark">
              Revolutionizing Your Operations with Intelligent Automation
            </h1>
            <p className="">
              At Scimitar Hindustan, we specialize in providing comprehensive
              Industrial Automation solutions that enhance productivity,
              efficiency, and safety in manufacturing and production
              environments. Our tailored automation systems are designed to
              optimize every aspect of your operations, from production lines to
              logistics, using cutting-edge technology like robotics, sensors,
              and advanced control systems.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
            <img
              className="Revolution_Image_section"
              src={require("../assets/img/Home/project-2.jpg")}
            ></img>
          </div>
        </div>
      </div>

      {/* Revolutionizing Your Operations with Intelligent Automation end */}

      <div className="container mt-5 mb-5">
        <h1 className="text-warning text-center">
          Our Industrial Automation Solutions
        </h1>
      </div>

      {/* Our Industrial Automation Solutions start */}

      <div className="container mb-5">
        <div className="row" data-aos="fade-left">
          <div className="col-lg-7 col-md-7 col-sm-12">
            <h1 className="">Automated Production Lines</h1>
            <p className="">
              We design and implement fully automated production lines that
              streamline operations and reduce human intervention. From assembly
              to quality control, our automated systems ensure faster production
              with fewer errors, helping you meet demand with precision and
              speed.
            </p>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection Industrail_CardSection_1 p-1 text-center ">
                    <p className="mt-2">
                      Real-time data monitoring for enhanced decision-making
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection Industrail_CardSection_2 p-1 text-center">
                    <p className="mt-2 ">
                      Integration with existing systems for seamless operation
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection Industrail_CardSection_3 p-1 text-center">
                    <p className="mt-2">
                      Customizable solutions for different industries and
                      product types
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection Industrail_CardSection_4 p-1 text-center">
                    <p className="mt-2">
                      Improved production accuracy and consistency
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-12 col-md-12 col-sm-6">
                  <div className="card industrail_cardSection Industrail_CardSection_5 p-1 text-center">
                    <p className="mt-2">
                      Reduced labor costs and operational inefficiencies
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/project-4.jpg")}
            ></img>
          </div>
        </div>
      </div>

      {/* Our Industrial Automation Solutions end */}

      {/* Robotic Process Automation start */}

      <div className="container mt-5">
        <div className="row" data-aos="fade-right">
          <div className="col-lg-5 col-md-5 col-sm-12">
            <img
              className="Robotics_Process_image"
              src={require("../assets/img/Home/project-4.jpg")}
            ></img>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12">
            <h1 className="">Robotic Process Automation </h1>
            <p className="">
              Our robotic automation solutions enhance efficiency by automating
              repetitive, labor-intensive tasks such as material handling,
              welding, painting, and packaging. Our robots are designed to
              handle complex tasks with speed and accuracy, reducing downtime
              and increasing throughput.
            </p>
            <div className="container">
              <div className=" ">
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mx-2 "
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Adaptability to different tasks and environments
                </h6>
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Easy integration into existing production lines
                </h6>
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Enhanced safety with human-robot collaboration
                </h6>
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  High-speed, high-precision operation
                </h6>
                <h6 className="">
                  <i
                    className="fa-solid fa-circle-check text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Optimized for energy efficiency and minimal maintenance
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Robotic Process Automation end */}

      {/* Advanced Control Systems starts */}

      <div className="container mt-5 mb-5">
        <h1 className=" text-center">Advanced Control Systems</h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-2 col-sm-6">
            <div
              className="card Advance_section_card bg-secondary text-white p-5"
              data-aos="fade-right"
            >
              <div className="text-center h3 advanced_section_icon">
                <i class="fa-solid fa-circle-check h1 text-warning Advance_section_card_icon"></i>
              </div>
              <p className=" mt-5">
                PLC and SCADA systems are essential components in modern
                industrial automation
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-sm-6">
            <div
              className="card Advance_section_card p-5"
              data-aos="fade-right"
            >
              <div className="text-center h3 advanced_section_icon">
                <i class="fa-solid fa-circle-check  h1 text-warning Advance_section_card_icon"></i>
              </div>
              <p className=" mt-5">
                Real-time data visualization involves the use of graphical
                representations, such as charts
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-sm-6">
            <div
              className="card Advance_section_card bg-secondary text-white p-5"
              data-aos="fade-left"
            >
              <div className="text-center h3 advanced_section_icon ">
                <i class="fa-solid fa-circle-check h1 text-warning Advance_section_card_icon"></i>
              </div>
              <p className=" mt-5">
                Advanced analytics for predictive maintenance and performance
                optimization
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-sm-6">
            <div className="card Advance_section_card p-5" data-aos="fade-left">
              <div className="text-center h3 advanced_section_icon">
                <i class="fa-solid fa-circle-check h1 text-warning  Advance_section_card_icon"></i>
              </div>
              <p className=" mt-5">
                Seamless integration with sensors, actuators, and other
                equipment is a fundamental aspect
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Advanced Control Systems end */}

      {/* Sensors and Actuators start */}

      <div className="container mt-5 mb-5">
        <div className="row" data-aos="fade-left">
          <div className="col-lg-7 col-md-7 col-sm-12">
            <h1 className="">Sensors and Actuators</h1>
            <p className="">
              Our sensors and actuators play a critical role in ensuring the
              smooth operation of your automated systems. From monitoring
              conditions like temperature and pressure to executing precise
              mechanical movements, these components provide real-time feedback
              and control for enhanced system performance.
            </p>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection Industrail_CardSection_1 p-1 text-center">
                    <p className="mt-2">High-precision sensors for real-time</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection Industrail_CardSection_2 p-1 text-center">
                    <p className="mt-2">
                      Durable actuators for fast responsive
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection Industrail_CardSection_3 p-1 text-center">
                    <p className="mt-2">
                      Easy integration into existing systems
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card industrail_cardSection Industrail_CardSection_4 p-1 text-center">
                    <p className="mt-2">Increased safety and accuracy</p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-12 col-md-12 col-sm-6">
                  <div className="card industrail_cardSection Industrail_CardSection_5 p-1 text-center">
                    <p className="mt-2">
                      Custom configurations available for specific needs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/project-4.jpg")}
            ></img>
          </div>
        </div>
      </div>

      {/* Sensors and Actuators end */}

      {/* Why Choose Industrial Automation? start */}

      <div className="container-fluid footer_container_1 p-5 mb-5">
        <div className="container mt-5 mb-5">
          <h1 className="text-warning text-center">
            Why Choose Industrial Automation?
          </h1>
        </div>

        <div className="row p-5 ">
          <div className="col-lg-6 col-md-6 col-sm-6 ">
            <div className="row  Why_Choose_industrail">
              <div className="col-lg-2 text-end Why_Choose_industrail_ICon">
                <i class="fa-solid fa-house h1  text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b>Increased Productivity: </b>Automated systems can operate
                  24/7, allowing you to maximize production output while
                  maintaining consistent quality.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="row Why_Choose_industrail">
              <div className="col-lg-2 text-end Why_Choose_industrail_ICon">
                {/* <i class="fa-solid fa-house h1 text-warning"></i> */}
                {/* <i class="fa-solid fa-chart-simple h1 text-warning"></i> */}
                <i class="fa-solid fa-chart-line h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b>Cost Reduction: </b>By reducing manual labor and minimizing
                  errors, automation helps lower operational costs while
                  improving overall efficiency.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-5 Why_Choose_industrail_Row_2">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="row Why_Choose_industrail">
              <div className="col-lg-2 text-end Why_Choose_industrail_ICon">
                {/* <i class="fa-solid fa-house h1 text-warning"></i> */}
                <i class="fa-solid fa-shield h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b>Improved Safety: </b>Automation reduces the need for human
                  intervention in dangerous tasks, leading to a safer work
                  environment and fewer workplace accidents.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="row Why_Choose_industrail">
              <div className="col-lg-2 text-end Why_Choose_industrail_ICon">
                {/* <i class="fa-solid fa-house h1 text-warning"></i> */}
                <i class="fa-solid fa-star h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b>Precision and Consistency: </b>Automated systems ensure
                  that every process is performed with precision, leading to
                  consistent product quality and reduced waste.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-5 Why_Choose_industrail_Row_2">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="row Why_Choose_industrail">
              <div className="col-lg-2 text-warning  text-end Why_Choose_industrail_ICon">
                {/* <i class="fa-solid fa-house h1 text-warning"></i> */}
                <i class="fa-solid fa-bolt h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b>Energy Efficiency: </b>Our advanced control systems
                  optimize energy use, reducing power consumption and cutting
                  down on operational expenses.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="row Why_Choose_industrail">
              <div className="col-lg-2  text-end Why_Choose_industrail_ICon">
                {/* <i class="fa-solid fa-house h1 text-warning"></i> */}
                <i class="fa-solid fa-maximize h1 text-warning"></i>
              </div>
              <div className="col-lg-10 text-white">
                <span>
                  <b>Scalability: </b>Whether you’re looking to automate a
                  single process or your entire production line, our solutions
                  are scalable and flexible, ensuring they grow with your
                  business.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Industrial Automation? end */}

      {/*  */}

      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <img
              src={require("../assets/img/Home/feature.jpg")}
              className="Industries_We_Serve_Image"
            ></img>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 Industries_We_Serve">
            <div className="container" style={{ marginLeft: "50px" }}>
              <h2 className="text-warning">Industries We Serve:</h2>
              {/* <div className=""> */}
              <div className=" " data-aos="fade-right">
                <h6 className="">
                  <i
                    className="fas fa-arrow-right text-warning mx-2 "
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Automotive
                </h6>
                <h6 className="">
                  <i
                    className="fas fa-arrow-right text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Food & Beverage
                </h6>
                <h6 className="">
                  <i
                    className="fas fa-arrow-right text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Pharmaceutical
                </h6>
                <h6 className="">
                  <i
                    className="fas fa-arrow-right text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Manufacturing
                </h6>
                <h6 className="">
                  <i
                    className="fas fa-arrow-right text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Energy & Utilities
                </h6>
              </div>
              {/* </div> */}
            </div>

            <div className="container mt-5" style={{ marginLeft: "50px" }}>
              <h2 className="text-warning">
                Benefits of Industrial Automation:
              </h2>
              {/* <div className=""> */}
              <div className=" " data-aos="fade-left">
                <h6 className="">
                  <i
                    className="fas fa-arrow-right text-warning mx-2 "
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Efficiency Gains
                </h6>
                <h6 className="">
                  <i
                    className="fas fa-arrow-right text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Improved Quality Control
                </h6>
                <h6 className="">
                  <i
                    className="fas fa-arrow-right text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Labor Optimization
                </h6>
                <h6 className="">
                  <i
                    className="fas fa-arrow-right text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Sustainability
                </h6>
                <h6 className="">
                  <i
                    className="fas fa-arrow-right text-warning mt-2 mx-2"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  Real-Time Data and Insights
                </h6>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      {/*  */}

      {/* Our Process start */}

      {/* <div className="container mt-5 mb-5">
        <h1 className="text-warning text-center">Our Process</h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="card step-card">
              {steps.map((step) => (
                <div key={step.number} className={`row text-warning mt-5`}>
                  <div className="col-lg-2">
                    <h3
                      className={`step-number ${
                        selectedStep?.number === step.number ? "active" : ""
                      }`}
                      onClick={() => handleClick(step.number)}
                    >
                      {step.number}
                    </h3>
                  </div>
                  <div className="col-lg-10">
                    <h4>{step.heading}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-7">
            <div className="container our_process_container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-6 Heading_Industrial">
                  {selectedStep && <h2>{selectedStep.heading}</h2>}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-6 subHeading_Industrial">
                  {selectedStep && <p>{selectedStep.subheading}</p>}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-6 step-image">
                  {selectedStep && (
                    <img
                      src={selectedStep.image}
                      alt={`Step ${selectedStep.number}`}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

      {/* <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className=" p-5">
              <h1 className="">Consultation and Needs Assessment:</h1>
              <p className="">
                We begin by understanding your business, goals, and challenges.
                Our team will assess your current processes to identify
                automation opportunities and tailor solutions to meet your
                needs.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/10001.jpg")}
              style={{ width: "500px", height: "400px" }}
            ></img>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/10001.jpg")}
              style={{ width: "500px", height: "400px" }}
            ></img>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className=" p-5">
              <h1 className="">Custom Solution Design:</h1>
              <p className="">
                Based on the assessment, we design a customized automation
                system that integrates the latest technologies, ensuring it fits
                seamlessly into your existing operations and aligns with your
                long-term goals.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className=" p-5">
              <h1 className="">Implementation and Integration:</h1>
              <p className="">
                Our team of experts will install and integrate the automation
                system with minimal disruption to your operations, ensuring a
                smooth transition and maximum uptime.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/10001.jpg")}
              style={{ width: "500px", height: "400px" }}
            ></img>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/10001.jpg")}
              style={{ width: "500px", height: "400px" }}
            ></img>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className=" p-5">
              <h1 className="">Training and Support: </h1>
              <p className="">
                We provide comprehensive training for your team, ensuring they
                can operate and maintain the system efficiently. We also offer
                ongoing support to address any technical issues and keep your
                system running smoothly.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className=" p-5">
              <h1 className="">Continuous Optimization:</h1>
              <p className="">
                As your business evolves, so should your automation system. We
                offer continuous monitoring and optimization services to ensure
                your system adapts to your changing needs, ensuring peak
                performance at all times.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img
              className=""
              src={require("../assets/img/Home/10001.jpg")}
              style={{ width: "500px", height: "400px" }}
            ></img>
          </div>
        </div>
      </div> */}

      {/* Our Process end */}

      {/* Why Choose Scimitar Hindustan start */}

      <div className="container mt-5 mb-5">
        <h1 className="text-dark text-center">Why Choose Scimitar Hindustan</h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div
              className="card p-4 bg-white Why_Choose_Scimitar_Hindustan_CardSection_1"
              data-aos="fade-right"
            >
              <h4 className="text-warning">Expertise and Innovation </h4>
              <p className="text-dark">
                With years of experience in the industrial automation industry,
                we deliver innovative solutions that drive efficiency and
                growth.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div
              className="card p-4 bg-white Why_Choose_Scimitar_Hindustan_CardSection_1"
              data-aos="fade-up"
            >
              <h4 className="text-warning">Tailored Solutions </h4>
              <p className="text-dark">
                We understand that every business is unique. That’s why we offer
                customized automation solutions that align with your specific
                needs and goals.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div
              className="card p-4 bg-white Why_Choose_Scimitar_Hindustan_CardSection_1"
              data-aos="fade-left"
            >
              <h4 className="text-warning">Cutting-Edge Technology</h4>
              <p className="text-dark">
                We leverage the latest advancements in robotics, AI, machine
                learning, and IoT to provide state-of-the-art automation
                solutions.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-5 ">
          <div className="col-lg-2 col-md-2 col-sm-2"></div>
          <div className="col-lg-4 col-md-4 col-sm-12 Why_Choose_Scimitar_Hindustan_CardSection">
            <div
              className="card p-4 text-center bg-white Why_Choose_Scimitar_Hindustan_CardSection_1"
              data-aos="fade-down"
            >
              <h4 className="text-warning">Commitment to Quality</h4>
              <p className="text-dark">
                We prioritize quality and precision in everything we do,
                ensuring that our solutions meet the highest industry standards.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <div
              className="card p-4 text-center bg-white Why_Choose_Scimitar_Hindustan_CardSection_1"
              data-aos="fade-down"
            >
              <h4 className="text-warning">End-to-End Support</h4>
              <p className="text-dark">
                From consultation and design to implementation and ongoing
                support, we are with you every step of the way industry
                standard.
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2"></div>
        </div>
      </div>

      {/* Why Choose Scimitar Hindustan end */}

      {/* <div className="container mt-5 mb-5">
        <h1 className="text-warning text-center">
          Take Your Business to the Next Level with Industrial Automation
        </h1>
      </div>
      <div className="container-fluid mt-5 mb-5">
        <p className=" text-center">
          Ready to transform your operations and boost efficiency? Contact
          <b className="text-warning mx-2">Scimitar Hindustan</b>today to
          discuss how our industrial automation solutions can benefit your
          business.
        </p>
      </div> */}

      {/*  */}

      {/*  */}
    </>
  );
}

export default IndustrialAutomation;
