import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../assets/css/Home.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaArrowRight } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
// import { duration } from "@mui/material";
import { Helmet } from "react-helmet";
import data from "../../src/component/ApiUrl/meta_api";
import videoSrc from "../assets/img/Home/Scimitar Automation.mp4";

function Home() {
  const testimonials = [
    {
      text: "Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.",
      name: "Client Name",
      profession: "Profession",
    },
    {
      text: "Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.",
      name: "Client Name",
      profession: "Profession",
    },
    {
      text: "Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.",
      name: "Client Name",
      profession: "Profession",
    },
    {
      text: "Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.",
      name: "Client Name",
      profession: "Profession",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // Change this value based on your needs
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [meta, setMeta] = useState(null);

  const fetchData = () => {
    const HomeMeta = data[0]?.home_page;
    if (HomeMeta) {
      setMeta(HomeMeta);
    }
  };

  useEffect(() => {
    fetchData();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []); // Added data as a dependency

  const [yearsExperience, setYearsExperience] = useState(0);
  const [teamMembers, setTeamMembers] = useState(0);
  const [happyClients, setHappyClients] = useState(0);
  const [projectsDone, setProjectsDone] = useState(0);

  const targetYearsExperience = 7;
  const targetTeamMembers = 10;
  const targetHappyClients = 100;
  const targetProjectsDone = 100;

  // Common duration for counting (in milliseconds)
  const duration = 3000; // 3 seconds
  const intervals = [
    targetYearsExperience,
    targetTeamMembers,
    targetHappyClients,
    targetProjectsDone,
  ];

  useEffect(() => {
    const countUp = (target, setCount) => {
      const totalSteps = target;
      const stepDuration = duration / totalSteps; // Calculate time per step
      let count = 0;

      const interval = setInterval(() => {
        if (count <= totalSteps) {
          setCount(count++);
        } else {
          clearInterval(interval);
        }
      }, stepDuration);

      return interval;
    };

    const intervalsArr = [
      countUp(targetYearsExperience, setYearsExperience),
      countUp(targetTeamMembers, setTeamMembers),
      countUp(targetHappyClients, setHappyClients),
      countUp(targetProjectsDone, setProjectsDone),
    ];

    // Cleanup function to clear all intervals on component unmount
    return () => {
      intervalsArr.forEach((interval) => clearInterval(interval));
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{meta ? meta.title : "Home"}</title>
      </Helmet>

      <div className="container-fluid px-0 mb-5">
        <div
          id="header-carousel"
          className="carousel slide "
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <video
                className="w-100 object-fit-cover"
                src={videoSrc}
                alt="1"
                controls
                autoPlay
                muted // Muting is often necessary for autoplay to work in many browsers
                loop // Optional: to loop the video
              />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-10 text-start">
                      <p className="fs-5 fw-medium text-warning text-uppercase animated slideInRight">
                        Empowering Industries with Cutting-Edge Automation
                      </p>
                      <h1 className="display-1 text-white fs-1 mb-5 animated slideInRight">
                        Innovative Solutions to Optimize Operations and Drive
                        Growth
                      </h1>
                      <a
                        href="#"
                        className="btn btn-warning py-3 px-5 animated slideInRight"
                      >
                        Explore More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                className="w-100   object-fit-cover"
                src={require("../assets/img/Banner/Banner_1.jpg")}
                alt="Image 2"
              />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-10 text-start ">
                      <p className="fs-5 fw-medium text-warning text-uppercase animated slideInRight">
                        Empowering Industries with Cutting-Edge Automation
                      </p>
                      <h1 className="display-1 text-white fs-1 mb-5 animated slideInRight">
                        Innovative Solutions to Optimize Operations and Drive
                        Growth
                      </h1>
                      <a
                        href="#"
                        className="btn btn-warning py-3 px-5 animated slideInRight"
                      >
                        Explore More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      {/* about */}

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6">
              <div className="row gx-3 h-100" data-aos="zoom-in">
                <div
                  className="col-6 align-self-start wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <img
                    className="img-fluid "
                    src={require("../assets/img/Home/about-1.jpg")}
                    alt="About 1"
                  />
                </div>
                <div
                  className="col-6 align-self-end wow fadeInDown"
                  data-wow-delay="0.1s"
                >
                  <img
                    className="img-fluid mt-5"
                    src={require("../assets/img/Home/about-2.jpg")}
                    alt="About 2"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeIn"
              data-wow-delay="0.5s"
              data-aos="zoom-in"
            >
              <p className="fw-medium text-uppercase text-warning mb-2">
                About Us
              </p>
              <h1 className="display-5 mb-4">Introduction</h1>
              <p className="mb-4">
                Welcome to Scimitar Hindustan, where industrial innovation meets
                operational excellence. With decades of experience, we
                specialize in designing and implementing state-of-the-art
                automation solutions tailored to meet the diverse needs of
                industries worldwide. Our mission is to transform how businesses
                operate by providing reliable, efficient, and scalable
                automation technologies.
              </p>
              <div className="d-flex align-items-center mb-4">
                <div className="flex-shrink-0 bg-secondary p-4">
                  <h1 className="display-2 text-warning">7+</h1>
                  <h5 className="text-white">Years of</h5>
                  <h5 className="text-white">Experience</h5>
                </div>
                <div className="ms-4">
                  <p>
                    <i className="fa fa-check text-warning me-2"></i>
                    Industrial Automation
                  </p>
                  <p>
                    <i className="fa fa-check text-warning me-2"></i>
                    Control Systems Design
                  </p>
                  <p>
                    <i className="fa fa-check text-warning me-2"></i>
                    Maintenance & Support
                  </p>
                  <p>
                    <i className="fa fa-check text-warning me-2"></i>
                    Consulting Services
                  </p>
                  <p className="mb-0">
                    <i className="fa fa-check text-warning me-2"></i>
                    Energy Efficiency Solutions
                  </p>
                </div>
              </div>
              <div className="row pt-2">
                <div className="col-sm-7">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-warning">
                      <i className="fa fa-envelope-open text-white"></i>
                    </div>
                    <div className="ms-3">
                      <p className="mb-2">Email us</p>
                      <h6 className="mb-0">contact@scimitarautomation.com</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-warning">
                      <i className="fa fa-phone text-white"></i>
                    </div>
                    <div className="ms-3">
                      <p className="mb-2">Call us</p>
                      <h6 className="mb-0">+91- 812 231 7080</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* about end */}

      {/* Facts Start */}

      {/* <div className="container-fluid bg-secondary facts my-5 p-5 facts_content">
        <div className="row g-5 ">
          <div className="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.1s">
            <div className="text-center border p-5">
              <img
                className="facts_sectionIcon"
                src={require("../assets/img/Home/trustworthiness.png")}
              ></img>

              <h1
                className="display-2 text-white mb-0"
                data-toggle="counter-up"
              >
                25
              </h1>
              <span className="fs-5 fw-semi-bold text-white">
                Years Experience
              </span>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.3s">
            <div className="text-center border p-5">
              <img
                className="facts_sectionIcon"
                src={require("../assets/img/Home/group.png")}
              ></img>
              <h1
                className="display-2 text-white mb-0"
                data-toggle="counter-up"
              >
                135
              </h1>
              <span className="fs-5 fw-semi-bold text-white">Team Members</span>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.5s">
            <div className="text-center border p-5">
              <img
                className="facts_sectionIcon"
                src={require("../assets/img/Home/customer-engagement.png")}
              ></img>
              <h1
                className="display-2 text-white mb-0"
                data-toggle="counter-up"
              >
                957
              </h1>
              <span className="fs-5 fw-semi-bold text-white">
                Happy Clients
              </span>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.7s">
            <div className="text-center border p-5">
              <img
                className="facts_sectionIcon"
                src={require("../assets/img/Home/project-management.png")}
              ></img>
              <h1
                className="display-2 text-white mb-0"
                data-toggle="counter-up"
              >
                1839
              </h1>
              <span className="fs-5 fw-semi-bold text-white">
                Projects Done
              </span>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-fluid bg-secondary facts my-5 p-5 facts_content">
        <div className="row g-5">
          <div className="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.1s">
            <div className="text-center border p-5">
              <img
                className="facts_sectionIcon"
                src={require("../assets/img/Home/trustworthiness.png")}
                alt="Trustworthiness Icon"
              />
              <h1
                className="display-2 text-white mb-0"
                data-toggle="counter-up"
              >
                {yearsExperience}
              </h1>
              <span className="fs-5 fw-semi-bold text-white">
                Years Experience
              </span>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.3s">
            <div className="text-center border p-5">
              <img
                className="facts_sectionIcon"
                src={require("../assets/img/Home/group.png")}
                alt="Team Members Icon"
              />
              <h1
                className="display-2 text-white mb-0"
                data-toggle="counter-up"
              >
                {teamMembers}
              </h1>
              <span className="fs-5 fw-semi-bold text-white">Team Members</span>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.5s">
            <div className="text-center border p-5">
              <img
                className="facts_sectionIcon"
                src={require("../assets/img/Home/customer-engagement.png")}
                alt="Happy Clients Icon"
              />
              <h1
                className="display-2 text-white mb-0"
                data-toggle="counter-up"
              >
                {happyClients}
              </h1>
              <span className="fs-5 fw-semi-bold text-white">
                Happy Clients
              </span>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.7s">
            <div className="text-center border p-5">
              <img
                className="facts_sectionIcon"
                src={require("../assets/img/Home/project-management.png")}
                alt="Projects Done Icon"
              />
              <h1
                className="display-2 text-white mb-0"
                data-toggle="counter-up"
              >
                {projectsDone}
              </h1>
              <span className="fs-5 fw-semi-bold text-white">
                Projects Done
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Facts end */}

      {/* Features start */}

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              data-aos="fade-right"
            >
              <div className="position-relative me-lg-4">
                <img
                  className="img-fluid w-100"
                  src={require("../assets/img/images (19).jpg")}
                  alt="Feature"
                />
                <span
                  className="position-absolute top-50 start-100 translate-middle bg-white rounded-circle d-none d-lg-block"
                  style={{ width: "120px", height: "120px" }}
                ></span>
                <button
                  type="button"
                  className="btn-play"
                  data-bs-toggle="modal"
                  data-src="https://www.youtube.com/embed/DWRcNpR6Kdc"
                  data-bs-target="#videoModal"
                >
                  <span></span>
                </button>
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.5s"
              data-aos="fade-left"
            >
              <p className="fw-medium text-uppercase text-warning mb-2">
                Why Choose Us!
              </p>
              <h1 className="display-5 mb-4">
                <p className="mb-4">
                  Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit.
                  Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit,
                  sed stet lorem sit clita duo justo magna dolore erat amet
                </p>
              </h1>
              {/* <p className="mb-4">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit.
                Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit,
                sed stet lorem sit clita duo justo magna dolore erat amet
              </p> */}
              <div className="row gy-4">
                <div className="col-12">
                  <div className="d-flex">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-warning">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <div className="ms-4">
                      <h4>Expert Team</h4>
                      <span>
                        Our engineers are leaders in automation technologies,
                        offering tailored solutions for various industries.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-warning">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <div className="ms-4">
                      <h4>Innovative Solutions</h4>
                      <span>
                        We use the latest technologies to design solutions that
                        drive efficiency and reduce costs.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-warning">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <div className="ms-4">
                      <h4>Reliable Industrial Services</h4>
                      <span>
                        We provide end-to-end support, ensuring your operations
                        run without a hitch.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-warning">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <div className="ms-4">
                      <h4>Proven Track Record</h4>
                      <span>
                        We've partnered with top-tier clients, delivering
                        exceptional results and driving business growth.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features end */}

      {/* Service start */}

      {/* <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-4 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <p className="fw-medium text-uppercase text-warning mb-2">
              Our Products
            </p>
            <h1 className="">We Provide Best Industrial Services</h1>
          </div>
          <div className="row gy-5 gx-4">
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <img
                  className="img-fluid"
                  src={require("../assets/img/Home/service-1.jpg")}
                  alt="Civil & Gas Engineering"
                />
                <div className="service-img">
                  <img
                    className="img-fluid"
                    src={require("../assets/img/Home/service-1.jpg")}
                    alt="Civil & Gas Engineering"
                  />
                </div>
                <div className="service-detail">
                  <div className="service-title">
                    <hr className="w-25" />
                    <h3 className="mb-0">Civil & Gas Engineering</h3>
                    <hr className="w-25" />
                  </div>
                  <div className="service-text">
                    <p className="text-white mb-0">
                      Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                      lorem sed diam stet diam sed stet.
                    </p>
                  </div>
                </div>
                <a className="btn btn-light" href="#">
                  Read More
                </a>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item">
                <img
                  className="img-fluid"
                  src={require("../assets/img/Home/service-2.jpg")}
                  alt="Power & Energy Engineering"
                />
                <div className="service-img">
                  <img
                    className="img-fluid"
                    src={require("../assets/img/Home/service-2.jpg")}
                    alt="Power & Energy Engineering"
                  />
                </div>
                <div className="service-detail">
                  <div className="service-title">
                    <hr className="w-25" />
                    <h3 className="mb-0">Power & Energy Engineering</h3>
                    <hr className="w-25" />
                  </div>
                  <div className="service-text">
                    <p className="text-white mb-0">
                      Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                      lorem sed diam stet diam sed stet.
                    </p>
                  </div>
                </div>
                <a className="btn btn-light" href="#">
                  Read More
                </a>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item">
                <img
                  className="img-fluid"
                  src={require("../assets/img/Home/service-3.jpg")}
                  alt="Plumbing & Water Treatment"
                />
                <div className="service-img">
                  <img
                    className="img-fluid"
                    src={require("../assets/img/Home/service-3.jpg")}
                    alt="Plumbing & Water Treatment"
                  />
                </div>
                <div className="service-detail">
                  <div className="service-title">
                    <hr className="w-25" />
                    <h3 className="mb-0">Plumbing & Water Treatment</h3>
                    <hr className="w-25" />
                  </div>
                  <div className="service-text">
                    <p className="text-white mb-0">
                      Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                      lorem sed diam stet diam sed stet.
                    </p>
                  </div>
                </div>
                <a className="btn btn-light" href="#">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Service end */}

      {/* service start */}

      {/* <section className="bg-light py-3 py-md-5 py-xl-8 service_cardSection">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 className="mb-4 display-5 text-center text-warning">
                Our Services
              </h2>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>

        <div className="container ">
          <div className="row">
            <div className="col-12">
              <div className="container-fluid">
                <div className="row gy-3 gy-md-4">
                  {services.map((service, index) => (
                    <div key={index} className="col-12 col-md-6 col-lg-4">
                      <div className="card border-dark">
                        <div className="card-body text-center p-4 p-xxl-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            fill="currentColor"
                            className={`bi bi-${service.iconPath} text-warning mb-4`}
                            viewBox="0 0 16 16"
                          >
                            <path d={service.iconPath} />
                          </svg>
                          <h4 className="mb-4">{service.title}</h4>
                          <p className="mb-4 text-secondary">
                            {service.description}
                          </p>
                          <a
                            href="#!"
                            className="btn btn-outline-warning bsb-btn-circle"
                          >
                            <FaArrowRight />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="bg-light py-3 py-md-5 py-xl-8 service_cardSection mt-5">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 className="mb-4 display-5 text-center text-warning">
                Our Services
              </h2>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row gy-3 gy-md-4">
            {/* <!-- First Row: 3 Cards --> */}
            <div className="col-12 col-md-4" data-aos="fade-right">
              <div className="card border-dark">
                <div className="card-body text-center p-4 p-xxl-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="currentColor"
                    className="bi bi-house text-warning mb-4"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.004-.001.274-.11a.75.75 0 0 1 .558 0l.274.11.004.001zm-1.374.527L8 5.962 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339Z" />
                  </svg>
                  <h4 className="mb-4">Industrial Automation</h4>
                  <p className="mb-4 text-secondary">
                    Customized automation systems designed to streamline
                    production.
                  </p>
                  <a
                    href="#!"
                    className="btn btn-outline-warning bsb-btn-circle"
                  >
                    <FaArrowRight />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4" data-aos="fade-down">
              <div className="card border-dark">
                <div className="card-body text-center p-4 p-xxl-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="currentColor"
                    className="bi bi-gear text-warning mb-4"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.828 8.9 8.9 1.827a4 4 0 1 1 5.657 5.657l-7.07 7.071A4 4 0 1 1 1.827 8.9Zm9.128.771 2.893-2.893a3 3 0 1 0-4.243-4.242L6.713 5.429z" />
                  </svg>
                  <h4 className="mb-4">Control Systems Design</h4>
                  <p className="mb-4 text-secondary">
                    Advanced and reliable systems to enhance operational
                    control.
                  </p>
                  <a
                    href="#!"
                    className="btn btn-outline-warning bsb-btn-circle"
                  >
                    <FaArrowRight />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4" data-aos="fade-left">
              <div className="card border-dark">
                <div className="card-body text-center p-4 p-xxl-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="currentColor"
                    className="bi bi-tools text-warning mb-4"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zM4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                  </svg>
                  <h4 className="mb-4">Maintenance & Support</h4>
                  <p className="mb-4 text-secondary">
                    Ensuring your systems run smoothly, 24/7.
                  </p>
                  <a
                    href="#!"
                    className="btn btn-outline-warning bsb-btn-circle"
                  >
                    <FaArrowRight />
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- Second Row: 2 Centered Cards --> */}
            <div className="col-12 d-flex justify-content-center">
              <div
                className="row gy-3 gy-md-4"
                style={{ maxWidth: "960px" }}
                data-aos="fade-up"
              >
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="card border-dark">
                    <div className="card-body text-center p-4 p-xxl-5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="48"
                        fill="currentColor"
                        className="bi bi-house text-warning mb-4"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.004-.001.274-.11a.75.75 0 0 1 .558 0l.274.11.004.001zm-1.374.527L8 5.962 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339Z" />
                      </svg>
                      <h4 className="mb-4">Consulting Services</h4>
                      <p className="mb-4 text-secondary">
                        Expert guidance for optimizing industrial in the
                        processes.
                      </p>
                      <a
                        href="#!"
                        className="btn btn-outline-warning bsb-btn-circle"
                      >
                        <FaArrowRight />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-6">
                  <div className="card border-dark">
                    <div className="card-body text-center p-4 p-xxl-5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="48"
                        fill="currentColor"
                        className="bi bi-battery-full text-warning mb-4"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293z" />
                      </svg>
                      <h4 className="mb-4">Energy Efficiency</h4>
                      <p className="mb-4 text-secondary">
                        Sustainable automation that reduces energy consumption.
                      </p>
                      <a
                        href="#!"
                        className="btn btn-outline-warning bsb-btn-circle"
                      >
                        <FaArrowRight />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* testimonial start */}
      <section className="container-xxl py-5">
        <div className="container">
          <div className="text-center mx-auto" style={{ maxWidth: "100px" }}>
            <h1 className="fw-medium text-uppercase text-warning mb-5">
              Testimonial
            </h1>
            {/* <h1 className="display-5 mb-5">What Our Clients Say!</h1> */}
          </div>
          <Slider {...settings} className="testimonial-carousel">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial-item text-center">
                <div className="testimonial-img position-relative">
                  {/* <div className="border home_testimonial rounded-circle h1 "> */}
                  <i class="fa-solid fa-user rounded-circle fs-1 text-white bg-secondary border p-5 mt-3"></i>
                  {/* </div> */}

                  <div className="btn-square bg-warning rounded-circle">
                    <i className="fa fa-quote-left text-white"></i>
                  </div>
                </div>
                <div className="testimonial-text text-center rounded p-4">
                  <div className="card  home_sectionTestimonial">
                    <p className="p-5">{testimonial.text}</p>
                    <h5 className="mb-1">{testimonial.name}</h5>
                    <span className="fst-italic">{testimonial.profession}</span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* testimonial end */}
    </>
  );
}

export default Home;
